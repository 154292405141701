import {
  REQUEST_QUOTE_FAIL,
  REQUEST_QUOTE_REQUEST,
  REQUEST_QUOTE_SUCCESS,
  SEND_MAIL_FAIL,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
} from "../constants/emailConstants";
import axios from "axios";
export const sendMail =
  (email, name, messageTitle, body) => async (dispatch) => {
    dispatch({
      type: SEND_MAIL_REQUEST,
      payload: { name, email, messageTitle, body },
    });
    try {
      const { data } = await axios.post(
        "https://netgenixicons-621eb2ce871f.herokuapp.com/api/api/v1/emails",
        {
          name,
          email,
          title: messageTitle,
          body,
        }
      );
      dispatch({ type: SEND_MAIL_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SEND_MAIL_FAIL,
        payload:
          error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message,
      });
    }
  };
export const requestQuote =
  (email, name, number, messageTitle, body) => async (dispatch) => {
    dispatch({
      type: REQUEST_QUOTE_REQUEST,
      payload: { name, email, messageTitle, body, number },
    });
    try {
      const { data } = await axios.post(
        "https://netgenixicons-621eb2ce871f.herokuapp.com/api/v1/emails/quote",
        {
          name,
          email,
          number,
          title: messageTitle,
          body,
        }
      );
      dispatch({ type: REQUEST_QUOTE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REQUEST_QUOTE_FAIL,
        payload:
          error.response && error.response.data.msg
            ? error.response.data.msg
            : error.message,
      });
    }
  };
