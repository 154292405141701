import { BiSolidChevronRight } from "react-icons/bi";
export const pageLinks = [
  { id: 0, name: "Home", url: "/" },
  {
    id: 1,
    icon: <BiSolidChevronRight color="#000" />,
    name: "About us +",
    links: [
      { id: 0, title: "Who We Are", url: "/who-we-are" },
      { id: 1, title: "Our Team", url: "/our-team" },
    ],
  },
  {
    id: 2,
    name: "Services +",
    icon: <BiSolidChevronRight color="#000" />,
    services: [
      { id: 0, title: "Web development" },
      { id: 1, title: "Mobile app development" },
      { id: 2, title: "Computer Aided Drawings" },
      { id: 3, title: "Product Design" },
      { id: 4, title: "Circuit Design" },
      { id: 5, title: "Simulations" },
      { id: 6, title: "IoT(Internet of Things)" },
      { id: 7, title: "LoRa Technology" },
      { id: 8, title: "Programming" },
    ],
  },
  { id: 3, name: "Testimonials", url: "/testimonials" },
  { id: 4, name: "Contact Us", url: "/contact-us" },
];
