import { AiFillAppstore, AiOutlineAntDesign } from "react-icons/ai";
import { FaCode } from "react-icons/fa";
import {
  MdLanguage,
  MdOutlineCastConnected,
  MdSettingsInputAntenna,
} from "react-icons/md";
import { SiVitest } from "react-icons/si";
import { GiDrawbridge, GiCircuitry } from "react-icons/gi";

export const services2 = [
  {
    id: 0,
    title: "Web development",
    image:
      "https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZSUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    desc1:
      "Our experienced web development team specializes in both front-end and back-end development, ensuring that your website not only looks stunning but also functions flawlessly",
    desc2:
      "We work closely with our clients to understand their specific needs and deliver tailor-made solutions",
  },
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXBwJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    title: "Mobile app development",
    desc1:
      "Whether you need an iOS, Android, or cross-platform app, our team creates seamless solutions tailored to your objectives. ",
    desc2:
      " From concept to launch, we focus on delivering exceptional performance and an outstanding user experience.",
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1617761141732-d481912af1a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGFyY2hpdGVjdHVyZSUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    title: "CAD Drawings",
    desc1:
      "Our CAD drawing services provide precise and detailed technical drawings for architecture, engineering, and manufacturing projects.",
    desc2:
      "We excel in 2D drafting and 3D modeling, helping you visualize concepts and streamline workflows with utmost accuracy.",
  },
  {
    id: 3,
    title: "Circuit Design ",
    image:
      "https://images.unsplash.com/photo-1562408590-e32931084e23?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2lyY3VpdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    desc1:
      "With our circuit design and simulation expertise, we optimize electronic systems and devices. ",
    desc2:
      "Our engineers excel in designing circuits for various applications, leveraging simulations to validate designs and ensure efficient functionality.",
  },
  {
    id: 4,
    title: "Product Design",
    image:
      "https://images.unsplash.com/photo-1561070791-36c11767b26a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80",
    desc1:
      " We combine creativity and technical expertise to create innovative and functional designs.",
    desc2:
      "Our team collaborates closely with clients to understand their vision, target audience, and market trends. ",
  },
  {
    id: 5,
    title: "LoRa Technology",
    image:
      "https://cdn.pixabay.com/photo/2019/12/05/11/18/computer-4674870_1280.png",
    desc1:
      "Our expertise in LoRa technology allows us to deliver cutting-edge solutions for long-range wireless communication. ",
    desc2:
      "With our deep understanding of LoRa technology, we offer comprehensive services including network design, device integration, and optimization. ",
  },
  {
    id: 6,
    title: "Programming ",
    image:
      "https://cdn.pixabay.com/photo/2016/03/27/18/54/technology-1283624_640.jpg",
    desc1: "We offer comprehensive programming solutions in any language .",
    desc2:
      "We cover a wide range of programming languages and frameworks, to deliver the best solutions to our clients.",
  },

  {
    id: 7,
    title: "Internet of Things ",

    image:
      "https://cdn.pixabay.com/photo/2018/04/21/02/11/iot-3337536_1280.png",
    desc1:
      "We offer end-to-end solutions tailored to your specific IoT needs, encompassing device integration, data analytics, cloud platform development,etc.",
    desc2:
      "Our comprehensive IoT services span across various industries, including smart buildings, healthcare, manufacturing, agriculture, and more. ",
  },
];

export const services3 = [
  {
    id: 0,
    title: "Web development",
    image:
      "https://images.unsplash.com/photo-1487014679447-9f8336841d58?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8d2Vic2l0ZSUyMGRldmVsb3BtZW50fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    desc1:
      "Our experienced web development team specializes in both front-end and back-end development, ensuring that your website not only looks stunning but also functions flawlessly.We work closely with our clients to understand their specific needs and deliver tailor-made solutions",
    subServices: [
      {
        id: 0,
        title: "Front-end Development",
        desc: " We specialize in creating fully responsive and visually appealing user interfaces for websites and web applications. Our expert team ensures seamless integration of design elements, enhancing user experience and optimizing performance.",
      },
      {
        id: 1,
        title: "Back-end Development",
        desc: "At our core, we are proficient in building robust server-side logic and efficient database management to support web applications. Our tailored solutions are designed to handle complex data processing and ensure smooth functionality.",
      },
      {
        id: 2,
        title: "E-commerce Solutions",
        desc: "We offer comprehensive e-commerce platforms that enable seamless buying and selling experiences. From intuitive product catalogs to secure payment gateways, we optimize every aspect to drive sales and boost customer satisfaction.",
      },
      {
        id: 3,
        title: "Content Management Systems",
        desc: "With our user-friendly content management systems, clients can effortlessly create, edit, and manage website content. We empower businesses with full control over their online presence, simplifying content updates.",
      },
      {
        id: 4,
        title: "Responsive Web Design",
        desc: "Our design approach prioritizes responsive web design, ensuring that websites adapt seamlessly to various devices and screen sizes. We craft engaging user experiences that leave a lasting impression on visitors.",
      },
      {
        id: 5,
        title: "Website Maintenance and Support",
        desc: "Beyond development, we provide ongoing website maintenance and dedicated technical support. Regular updates, security checks, and quick issue resolution keep your website running smoothly.",
      },
    ],
  },
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1480694313141-fce5e697ee25?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXBwJTIwZGV2ZWxvcG1lbnR8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60",
    title: "Mobile app development",
    desc1:
      "Whether you need an iOS, Android, or cross-platform app, our team creates seamless solutions tailored to your objectives. From concept to launch, we focus on delivering exceptional performance and an outstanding user experience.",
    subServices: [
      {
        id: 0,
        title: "iOS App Development",
        desc: "Our expertise lies in crafting high-quality and user-friendly mobile applications for Apple's iOS platform. We leverage the latest iOS features to deliver applications that delight users and meet business objectives.",
      },
      {
        id: 1,
        title: "Android App Development",
        desc: "From concept to deployment, we develop custom and engaging mobile applications for Android devices. Our apps are optimized for performance and cater to the diverse Android ecosystem.",
      },
      {
        id: 2,
        title: "Cross-Platform App Development",
        desc: " Our cross-platform app development approach allows us to build versatile applications that work seamlessly across multiple platforms, reducing development time and costs.",
      },
      { id: 3, title: "Mobile App UI/UX Design", desc: "" },
      {
        id: 4,
        title: "iOS App Development",
        desc: " We focus on creating intuitive and visually appealing user interfaces (UI) combined with exceptional user experiences (UX). Our designs prioritize ease of navigation and interactions for optimal user engagement.",
      },
      {
        id: 5,
        title: "App Testing and Quality Assurance",
        desc: "We conduct rigorous testing and quality assurance processes to ensure the functionality, security, and reliability of mobile apps. Our commitment to quality guarantees a smooth user experience.",
      },
    ],
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1617761141732-d481912af1a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjB8fGFyY2hpdGVjdHVyZSUyMGRlc2lnbnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    title: "Computer Aided Drawings",
    desc1:
      "Our CAD drawing services provide precise and detailed technical drawings for architecture, engineering, and manufacturing projects.We excel in 2D drafting and 3D modeling, helping you visualize concepts and streamline workflows with utmost accuracy.",
    subServices: [
      {
        id: 0,
        title: "2D CAD Drawings",
        desc: " Our precise and detailed two-dimensional technical drawings cater to a wide range of engineering and design projects. We adhere to industry standards and client requirements for accurate representation.",
      },
      {
        id: 1,
        title: "3D CAD Drawings",
        desc: "Leveraging advanced 3D modeling tools, we design intricate three-dimensional models that bring concepts to life. Our 3D CAD drawings facilitate visualization and aid in the development of products and structures.",
      },
      {
        id: 2,
        title: "Architectural CAD Drawings",
        desc: "Our comprehensive architectural CAD drawings provide detailed blueprints for construction projects. We focus on accuracy and attention to detail to ensure successful project execution.",
      },
      {
        id: 3,
        title: "Mechanical CAD Drawings",
        desc: "We create precise mechanical CAD drawings for components and systems using cutting-edge software. Our designs are optimized for manufacturing efficiency and functionality.",
      },
      {
        id: 4,
        title: "Electrical CAD Drawings",
        desc: "Our electrical CAD drawings encompass detailed schematics and diagrams for circuits and systems. We prioritize safety and compliance in our designs.",
      },
    ],
  },

  {
    id: 3,
    title: "Circuit Design",
    image:
      "https://images.unsplash.com/photo-1562408590-e32931084e23?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y2lyY3VpdHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    desc1:
      "With our circuit design and simulation expertise, we optimize electronic systems and devices.Our engineers excel in designing circuits for various applications, leveraging simulations to validate designs and ensure efficient functionality.",
    subServices: [
      {
        id: 0,
        title: "PCB Design and Layout",
        desc: "With a strong understanding of electronics, we design printed circuit board (PCB) layouts for efficient and compact electronics integration.",
      },

      {
        id: 1,
        title: "Analog Circuit Design",
        desc: "Our expertise in analog circuit design ensures precise signal processing and high performance for your electronic projects.",
      },
      {
        id: 2,
        title: "Power Electronics Design",
        desc: "We create innovative circuits and systems for power electronics, focusing on energy efficiency and reliability.",
      },
      {
        id: 3,
        title: "Circuit Simulation and Analysis",
        desc: "Through advanced simulations, we analyze electronic circuits to optimize performance and reliability.",
      },
      {
        id: 4,
        title: "PCB Prototyping and Testing",
        desc: "Our rapid prototyping and testing services facilitate quick iteration and verification of PCB designs.",
      },
    ],
  },
  {
    id: 4,
    title: "Product Design",
    image:
      "https://images.unsplash.com/photo-1561070791-36c11767b26a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=764&q=80",
    desc1:
      " We combine creativity and technical expertise to create innovative and functional designs.Our team collaborates closely with clients to understand their vision, target audience, and market trends. ",
    subServices: [
      {
        id: 0,
        title: "Concept Development",
        desc: "Our creative team excels in ideating and refining innovative concepts for new products or projects. We analyze market trends and user needs to shape winning ideas.",
      },
      {
        id: 1,
        title: "Industrial Design",
        desc: "Fusing aesthetics and functionality, we craft industrial designs that are not only visually appealing but also optimized for mass production. Our designs align with your brand identity and target audience preferences.",
      },
      {
        id: 2,
        title: "Prototyping",
        desc: "We build functional prototypes to validate and refine product concepts before production. Our iterative approach ensures that the final product meets your expectations and market demands.",
      },
      {
        id: 3,
        title: "Design for Manufacturing",
        desc: "Our design process takes manufacturing feasibility into account, optimizing products for cost-effective and efficient production.",
      },
      {
        id: 4,
        title: "Product Testing and Validation",
        desc: "We subject products to thorough testing and validation to ensure they meet the highest quality and safety standards. Our focus is on delivering reliable and durable products.",
      },
    ],
  },
  {
    id: 5,
    title: "LoRa Technology",
    image:
      "https://cdn.pixabay.com/photo/2019/12/05/11/18/computer-4674870_1280.png",
    desc1:
      "Our expertise in LoRa technology allows us to deliver cutting-edge solutions for long-range wireless communication. With our deep understanding of LoRa technology, we offer comprehensive services including network design, device integration, and optimization. ",
    subServices: [
      {
        id: 0,
        title: "LoRaWAN Network Design",
        desc: "Our LoRaWAN network design expertise enables long-range, low-power wireless communication for IoT applications.",
      },
      {
        id: 1,
        title: "LoRaWAN Application Development",
        desc: "We develop secure and scalable applications leveraging LoRaWAN technology to enable diverse IoT use cases.",
      },
      {
        id: 2,
        title: "LoRaWAN Security",
        desc: " Our comprehensive security measures protect data transmitted over LoRaWAN networks, ensuring privacy and data integrity.",
      },
      {
        id: 3,
        title: "LoRa Network Monitoring",
        desc: "We offer monitoring and management services for LoRaWAN networks to ensure optimal performance and reliability.",
      },
      {
        id: 4,
        title: "LoRaWAN Geolocation Services",
        desc: "Our geolocation services based on LoRaWAN technology provide accurate tracking and localization for IoT devices.",
      },
    ],
  },
  {
    id: 6,
    title: "Programming",
    image:
      "https://cdn.pixabay.com/photo/2016/03/27/18/54/technology-1283624_640.jpg",
    desc1:
      "We offer comprehensive programming solutions in any language. We cover a wide range of programming languages and frameworks, to deliver the best solutions to our clients.",
    subServices: [
      {
        id: 0,
        title: "C, C++",
        desc: "We offer expertise in C and C++ programming languages, powering applications that require high performance and efficiency.",
      },
      {
        id: 1,
        title: "Python",
        desc: "Our Python development services cater to a wide range of applications, from web development to data analysis and automation.",
      },
      {
        id: 2,
        title: "SQL, MongoDB",
        desc: "We provide comprehensive database solutions using SQL for structured data and MongoDB for NoSQL data, ensuring efficient data storage and retrieval.",
      },
      {
        id: 3,
        title: "Java, JavaScript",
        desc: "Our team excels in both Java and JavaScript, enabling us to create robust backend systems and interactive frontend experiences.",
      },
      {
        id: 4,
        title: "Microcontrollers (Arduino, Raspberry Pi)",
        desc: "We specialize in microcontroller programming for Arduino and Raspberry Pi, enabling the development of embedded systems and IoT projects.",
      },
    ],
  },

  {
    id: 7,
    title: "IoT(Internet of Things)",

    image:
      "https://cdn.pixabay.com/photo/2018/04/21/02/11/iot-3337536_1280.png",
    desc1:
      "We offer end-to-end solutions tailored to your specific IoT needs, encompassing device integration, data analytics, cloud platform development,etc.Our comprehensive IoT services span across various industries, including smart buildings, healthcare, manufacturing, agriculture, and more. ",
    subServices: [
      {
        id: 0,
        title: "IoT Solution Architecture",
        desc: "We design comprehensive and scalable architectures for Internet of Things (IoT) solutions, incorporating the latest technologies for seamless connectivity and data management.",
      },
      {
        id: 1,
        title: "IoT Hardware Development",
        desc: "Our custom hardware and sensor development caters to unique IoT requirements, ensuring reliable and secure communication.",
      },
      {
        id: 2,
        title: "IoT Data Analytics",
        desc: "Leveraging data analytics, we extract valuable insights from IoT device data, enabling data-driven decision-making.",
      },
      {
        id: 3,
        title: "IoT System Integration",
        desc: "Our expertise in IoT system integration ensures seamless communication and coordination between diverse IoT components.",
      },
      {
        id: 4,
        title: "IoT Maintenance and Support",
        desc: "We provide ongoing support, updates, and maintenance to ensure the optimal performance and security of IoT solutions.",
      },
    ],
  },
  {
    id: 7,
    title: "Simulations",

    image:
      "https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg",
    desc1:
      "Our simulations service empowers businesses and engineers to virtually test and analyze complex scenarios, systems, or processes. Leveraging advanced simulation tools and methodologies, we provide valuable insights, optimize designs, and make informed decisions without the need for physical prototypes. ",
    subServices: [
      {
        id: 0,
        title: "Structural Analysis",
        desc: "We provide comprehensive structural analysis services to ensure the integrity and safety of engineering designs, optimizing performance and reliability.",
      },
      {
        id: 1,
        title: "Design Optimization",
        desc: "Our expertise in design optimization helps in enhancing product efficiency, reducing material usage, and achieving cost-effective solutions.",
      },
      {
        id: 2,
        title: "Power Electronics Design",
        desc: "We specialize in designing power electronics circuits and systems, catering to efficient energy conversion and management.",
      },
      {
        id: 3,
        title: "Circuit Simulation and Analysis",
        desc: "Our advanced circuit simulation and analysis services ensure optimal performance and reliability of electronic circuits.",
      },
    ],
  },
];
export const services = [
  {
    id: 0,
    title: "Web development",
    icon: <MdLanguage color="#fff" />,
    subServices: [
      { id: 0, title: "Front-end Development" },
      { id: 1, title: "Back-end Development" },
      { id: 2, title: "E-commerce Solutions" },
      { id: 3, title: "Content Management Systems" },
      { id: 4, title: "Responsive Web Design" },
      { id: 5, title: "Website Maintenance and Support" },
    ],
  },
  {
    id: 1,
    title: "Mobile app development",
    icon: <AiFillAppstore color="#fff" />,
    subServices: [
      { id: 0, title: "iOS App Development" },
      { id: 1, title: "Android App Development" },
      { id: 2, title: "Cross-Platform App Development" },
      { id: 3, title: "Mobile App UI/UX Design" },
      { id: 4, title: "App Testing and Quality Assurance" },
    ],
  },
  {
    id: 2,
    title: "Computer Aided Drawings",
    icon: <GiDrawbridge color="#fff" />,
    subServices: [
      { id: 0, title: "2D CAD Drawings" },
      { id: 1, title: "3D CAD Drawings" },
      { id: 2, title: "Architectural CAD Drawings" },
      { id: 3, title: "Mechanical CAD Drawings" },
      { id: 4, title: "Electrical CAD Drawings" },
    ],
  },
  {
    id: 3,
    title: "Product Design",
    icon: <AiOutlineAntDesign color="#fff" />,
    subServices: [
      { id: 0, title: "Concept Development" },
      { id: 1, title: "Industrial Design" },
      { id: 2, title: "Prototyping " },
      { id: 3, title: "Design for Manufacturing" },
      { id: 4, title: "Product Testing and Validation" },
    ],
  },
  {
    id: 4,
    title: "Circuit Design",
    icon: <GiCircuitry color="#fff" />,
    subServices: [
      { id: 0, title: "PCB Design and Layout" },
      { id: 1, title: "Analog Circuit Design" },
      { id: 2, title: "Power Electronics Design" },
      { id: 3, title: "Circuit Simulation and Analysis" },
      { id: 4, title: "PCB Prototyping and Testing" },
    ],
  },
  {
    id: 5,
    title: "Simulations",
    icon: <SiVitest color="#fff" />,
    subServices: [
      { id: 0, title: "Structural Analysis" },
      { id: 1, title: "Design Optimization" },
      { id: 2, title: "Power Electronics Design" },
      { id: 3, title: "Circuit Simulation and Analysis" },
      { id: 4, title: "PCB Prototyping and Testing" },
    ],
  },
  {
    id: 6,
    title: "IoT(Internet of Things)",
    icon: <MdOutlineCastConnected color="#fff" />,
    subServices: [
      { id: 0, title: "IoT Solution Architecture" },
      { id: 1, title: "IoT Hardware Development" },
      { id: 2, title: "IoT Data Analytics" },
      { id: 3, title: "IoT System Integration" },
      { id: 4, title: "IoT Maintenance and Support" },
    ],
  },
  {
    id: 7,
    title: "LoRa Technology",
    icon: <MdSettingsInputAntenna color="#fff" />,
    subServices: [
      { id: 0, title: "LoRaWAN Network Design" },
      { id: 1, title: "LoRaWAN Application Development" },
      { id: 2, title: "LoRaWAN Security " },
      { id: 3, title: "LoRa Network Monitoring " },
      { id: 4, title: "LoRaWAN Geolocation Services" },
    ],
  },
  {
    id: 8,
    title: "Programming",
    icon: <FaCode color="#fff" />,
    subServices: [
      { id: 0, title: "C,C++" },
      { id: 1, title: "Python" },
      { id: 2, title: "SQL,MongoDB" },
      { id: 3, title: "Java,Javascript" },
      { id: 4, title: "Microcontrollers(Arduino,Raspberry)" },
    ],
  },
];
