import { AiOutlineMail } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

export const footerItems1 = [
  {
    id: 0,
    title: "Tel: (+254) 791 480 876",
    icon: <BiPhone color="#0c55e9" style={{ marginRight: "13px" }} />,
  },
  {
    id: 1,
    title: "Whatsapp: (+254) 791 480 876",
    icon: <FaWhatsapp color="#0c55e9" style={{ marginRight: "13px" }} />,
  },
  {
    id: 2,
    title: "info@netgenixicons.com",
    icon: <AiOutlineMail color="#0c55e9" style={{ marginRight: "13px" }} />,
  },
];
export const footerItems2 = [
  { id: 0, title: "Web development" },
  { id: 1, title: "Mobile app development" },
  { id: 2, title: "Computer Aided Drawings" },
  { id: 3, title: "Product Design" },
  { id: 4, title: "Circuit Design" },
  { id: 5, title: "Simulations" },
  { id: 6, title: "IoT(Internet of Things)" },
  { id: 7, title: "Programming" },
];
export const footerItems3 = [
  { id: 0, title: "Who We Are", url: "/who-we-are" },
  { id: 1, title: "Our Team", url: "/our-team" },
  { id: 3, title: "Testimonials", url: "/testimonials" },
  { id: 4, title: "Contact Us", url: "/contact-us" },
];
