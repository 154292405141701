import React from "react";
import styled from "styled-components";
const InputWithLabel = ({
  type,
  label,
  placeholder,
  onChange,
  required,
  value,
}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        onChange={onChange}
        required="true"
        value={value}
        type={type}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 13px;
  margin-right: 10px;
`;
const Label = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
  color: #000;
`;
const Input = styled.input`
  background: rgba(217, 217, 217, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 1px;
  padding: 16px;
  @media screen and (max-width: 800px) {
    padding: 10px 16px;
    font-size: 15px;
  }
  font-style: bold;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;

  color: #07174b;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.48);
  }
`;
export default InputWithLabel;
