import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { pageLinks } from "../data/pageLinks";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import {
  changeActiveAbout,
  changeActiveTab,
  openSidebar,
  changeActiveService,
} from "../redux/actions/activeTabActions";
const NavbarTwo = () => {
  const logo = process.env.PUBLIC_URL + "/FULLLOGO.png";
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 500;
      if (scrollY > threshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const activeIndex = useSelector((state) => state.activeTab);
  const isSidebarOpen = useSelector((state) => state.sidebarOpen);
  const dispatch = useDispatch();
  const [isAboutDetails, setIsAboutDetails] = useState(false);
  const [isServicesDetails, setIsServicesDetails] = useState(false);

  const handleParentDivHover = (index) => {
    index === 1 && setIsAboutDetails(true);
    index === 2 && setIsServicesDetails(true);
  };

  const handleParentDivMouseLeave = () => {
    setIsAboutDetails(false);
    setIsServicesDetails(false);
  };
  const handleClick = (index, pageLink) => {
    if (pageLink.links?.length > 0 || pageLink.services?.length > 0) {
      return;
    }
    dispatch(changeActiveTab(index));
    dispatch(changeActiveAbout(null));
    dispatch(changeActiveService(null));
  };
  const handleAboutClick = (index) => {
    dispatch(changeActiveTab(1));
    dispatch(changeActiveAbout(index));
    setIsAboutDetails(false);
  };
  const handleServiceClick = (index) => {
    dispatch(changeActiveTab(2));
    dispatch(changeActiveService(index));
    dispatch(changeActiveAbout(null));
    setIsServicesDetails(false);
  };
  const toggleSidebar = () => {
    dispatch(openSidebar(!isSidebarOpen.open));
  };
  return (
    <Wrapper scrolled={isScrolled}>
      <Content className="wrapper">
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>
        <Links>
          {pageLinks.map((pageLink, index) => (
            <Link to={pageLink.url}>
              <PageLink
                key={pageLink.id}
                active={index === (activeIndex.index || 0)}
                onClick={() => handleClick(index, pageLink)}
                onMouseEnter={() => handleParentDivHover(index)}
                onMouseLeave={() => handleParentDivMouseLeave(index)}
              >
                {pageLink.name}
                {pageLink.links?.length > 0 && (
                  <Details show={isAboutDetails}>
                    {pageLink.links.map((link, index) => (
                      <Link to={link.url}>
                        <Detail
                          key={link.id}
                          onClick={() => handleAboutClick(index)}
                          active={index === activeIndex.about}
                        >
                          {link.title}
                        </Detail>
                      </Link>
                    ))}
                  </Details>
                )}
                {pageLink.services?.length > 0 && (
                  <Details show={isServicesDetails}>
                    {pageLink.services.map((link, index) => (
                      <Link to={`/our-services/${link.title}`}>
                        <Detail
                          key={link.id}
                          onClick={() => handleServiceClick(index)}
                          active={index === activeIndex.service}
                        >
                          {link.title}
                        </Detail>
                      </Link>
                    ))}
                  </Details>
                )}
              </PageLink>
            </Link>
          ))}
        </Links>

        {/* <NavToggle>
          <FaBars onClick={showSidebar} />
        </NavToggle> */}
        <Bars onClick={toggleSidebar}>
          <Bar open={isSidebarOpen.open} />
          <Bar open={isSidebarOpen.open} />
          <Bar open={isSidebarOpen.open} />
        </Bars>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.nav`
  width: 100vw;
  background-color: #fff;
  z-index: 70;
  /* position: ${({ scrolled }) =>
    scrolled === true ? "sticky" : "relative"}; */
  position: sticky;
  top: 0;
  transition: all 2s linear;
  box-shadow: 0 0 8px #999;
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 85px !important;
  @media screen and (max-width: 800px) {
    height: 70px;
  }
`;
const Logo = styled.img`
  width: 65px;
  height: 65px;
  cursor: pointer;
  object-fit: cover;
  @media screen and (max-width: 800px) {
    width: 63px;
    height: 63px;
  }
`;
const Links = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const Details = styled.div`
  box-shadow: 3px 3px 3px lightgray;
  background-color: #fff;
  padding: 20px;
  display: flex;
  width: fit-content;
  transform-origin: top;
  flex-direction: column;
  position: absolute;
  z-index: 20;

  transform: ${({ show }) => (show ? " scaleY(1)" : " scaleY(0)")};
  transition: all 0.7s linear;
  top: 73px;
  min-width: 150px;
  width: fit-content;
  &::before {
    content: "";
    position: absolute;
    top: -60px;
    left: 0;
    width: 50%;
    height: 100px;
  }
`;
const PageLink = styled.div`
  font-family: "Anton,Sans serif";
  font-style: bold;
  font-weight: 800;
  height: 100%;
  font-size: 22px;
  line-height: 22px;
  color: ${(props) => (props.active ? "#0c55e9" : "#000")};
  margin-right: 40px;
  transition: all 0.5s linear;
  position: relative;
  padding: 20px 0;
  white-space: nowrap;

  &::after {
    content: "";
    position: absolute;
    height: 4px;
    bottom: 5px;
    left: 0px;
    right: 0px;
    background: #0c55e9;
    border-radius: 2px;
    display: ${(props) => (props.active ? "block" : "none")};
  }
  &:hover {
    color: #0c55e9;

    &::after {
      display: block;
    }
  }
`;
const Detail = styled.h1`
  font-family: "Anton,Sans serif";

  font-size: 16px;
  line-height: 22px;
  color: #000;
  margin-right: 40px;
  transition: all 0.5s linear;
  position: relative;
  padding: 10px 0;
  white-space: nowrap;
  color: ${(props) => (props.active ? "#0c55e9" : "#000")};
  &:hover {
    opacity: 0.8;
    color: #0c55e9;
  }
`;
// const NavToggle = styled.div`
//   cursor: pointer;
//   color: #141414;
//   margin-right: 20px;
//   transition: all 0.6s linear;
//   font-size: 33px;
//   @media screen and (min-width: 800px) {
//     display: none;
//   }
//   &:hover {
//     opacity: 0.7;
//     transform: scale(1.1);
//   }
// `;
const Bars = styled.div`
  cursor: pointer;
  padding: 10px 0;
  margin-right: 20px;
  transition: all 0.6s linear;
  display: flex;
  position: relative;
  width: 40px;
  height: 30px;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 800px) {
    display: none;
  }
  &:hover {
    opacity: 0.7;
    transform: scale(1.1);
  }
`;
const Bar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #141414;
  border-radius: 3px;
  position: absolute;

  &:first-child {
    transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0deg)")};
    top: ${({ open }) => (open ? "13px" : "0")};
  }
  &:nth-child(3) {
    transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0deg)")};
    bottom: ${({ open }) => (open ? "13px" : "0")};
  }
  &:nth-child(2) {
    display: ${({ open }) => (open ? "none" : "block")};
    transform: translateY(-50%);
    top: 50%;
  }
  transition: all 0.7s linear;
`;

export default NavbarTwo;
