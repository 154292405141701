import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import Service from "../components/Service";

import Service2 from "../components/Service2";
import { services, services2 } from "../data/services";

import HomeCard from "../components/HomeCard";
import { cardsData } from "../data/cardsData";
import { Link } from "react-router-dom";
import { Zoom, Fade } from "react-awesome-reveal";
import { HiOutlineArrowCircleRight } from "react-icons/hi";

const Home = () => {
  useEffect(() => {
    document.title = " Netgenix Tech Icons | Seamless Tech Excellence";
  }, []);
  const message = encodeURIComponent("Hello there. I need your services");

  const items = [
    "Web development",
    "Mobile app development",
    "Computer Aided Drawings",
    "Product Design",
    "Circuit Design",
    "Simulations",
    "IoT(Internet of Things)",
    "LoRa Technology",
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const servicesRef = useRef(null);

  const scrollToServices = () => {
    if (servicesRef.current) {
      const offset = -80;
      const targetPosition =
        servicesRef.current.getBoundingClientRect().top +
        window.scrollY +
        offset;
      window.scrollTo({ top: targetPosition, behavior: "smooth" });
    }
  };
  return (
    <Wrapper>
      <head>
        <meta
          name="keywords"
          content="Netgenix, IT company, IT solutions, Web development, IoT , App development, LoRa, Product Design, Circuit Design"
        />
      </head>
      <Content>
        <Section1>
          <ItemContents className="wrapper">
            <Title1>
              <Zoom triggerOnce={true}>Seamless Tech Excellence</Zoom>
            </Title1>
            <Services>
              <Title2>Our services:</Title2>
              <Title3 className="service">
                <Typewriter
                  options={{
                    strings: items,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </Title3>
            </Services>
            <Buttons>
              <Zoom cascade damping={0.25} triggerOnce={true}>
                <Button onClick={scrollToServices}>Our Services</Button>
                <Link to="/contact-us">
                  <Button blue>Contact Us</Button>
                </Link>
              </Zoom>
            </Buttons>
          </ItemContents>
        </Section1>
        <Zoom triggerOnce={true}>
          <Section2 className="wrapper">
            <Arrows>
              <Arrow3 />
              <Arrow2 />
              <Arrow />
              <h1>Welcome to Netgenix Tech Icons</h1>
            </Arrows>
            <Title4>
              Excellent <span>IT Solutions</span> for all!
            </Title4>
          </Section2>
        </Zoom>
        <Section3 className="wrapper" ref={servicesRef}>
          <Left>
            <Zoom cascade damping={0.1} triggerOnce={true}>
              {services.map((service, index) => (
                <Service
                  key={service.id}
                  {...service}
                  active={activeIndex === index}
                  onClick={() => handleClick(index)}
                />
              ))}
            </Zoom>
          </Left>
          <Right>
            <Zoom cascade damping={0.1} triggerOnce={true}>
              {services2.map((service) => (
                <Service2 key={service.id} {...service} />
              ))}
            </Zoom>
          </Right>
        </Section3>
        <Section4>
          <Fade duration={1400} style={{ width: "100%" }} triggerOnce={true}>
            {/* <Card className="wrapper">
              <h3>
                <span>Want to know more about us? </span>View Our Company
                Profile...
              </h3>
              <Link to="/our-profile">
                <button>
                  <FaArrowRight style={{ marginRight: "10px" }} />
                  View Profile
                </button>
              </Link>
            </Card> */}
            <ProfileCard>
              <div className="wrapper">
                <h3>
                  <span>Need a solution from us? </span> Click the button to
                  enquire for a service...
                </h3>
                <Link to={`/request-quote/Web development`}>
                  <button>
                    <HiOutlineArrowCircleRight
                      style={{ marginRight: "10px" }}
                    />
                    Request a Quote
                  </button>
                </Link>
              </div>
            </ProfileCard>
          </Fade>
          <ContactCardWrapper>
            <ContactCard>
              <div className="border">
                <ContactCardItems>
                  <Zoom cascade damping={0.2} triggerOnce={true}>
                    <h6>We are available </h6>
                    <h5>24 x 7 x 365</h5>
                    <h6>Call Us:</h6>
                    <a href="tel:+254791480876">(+254) 791 480 876</a>
                    <Whatsapp>
                      <a
                        id="whatsapp"
                        href={`https://wa.me/+254791480876?text=${message}`}
                      >
                        Or <span>Chat Us </span>on Whatsapp
                      </a>
                    </Whatsapp>
                    <h6>For real time support</h6>
                  </Zoom>
                </ContactCardItems>
              </div>
            </ContactCard>
          </ContactCardWrapper>
          <Cards className="wrapper">
            <Zoom cascade damping={0.2} triggerOnce={true}>
              {cardsData.map((card) => (
                <HomeCard key={card.id} {...card} />
              ))}
            </Zoom>
          </Cards>
        </Section4>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Section1 = styled.section`
  width: 100vw;
  height: fit-content;
  display: flex;
  margin: 0 auto 80px;

  position: relative;
  &::before {
    content: "";
    position: absolute;

    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 1;
  }
  @media screen and (max-width: 700px) {
    height: 350px;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;
`;
const Title1 = styled.h1`
  color: #fff;
  font-size: 64px;
  z-index: 5;

  @media screen and (max-width: 700px) {
    font-size: 40px;
  }
  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
`;
const Title2 = styled.h1`
  color: #fff;
  font-size: 32px;
  font-style: italic;
  z-index: 5;
`;
const Title3 = styled.h1`
  color: #fff;
  font-size: 22px;
  z-index: 5;
`;
const Title4 = styled.h1`
  color: #000;
  font-size: 20px;
  margin: 30px 0;
  span {
    font-size: 22px;
    color: #0c55e9;
  }
`;
const ItemContents = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px 40px;
  justify-content: center;
`;
const Buttons = styled.div`
  display: flex;
  z-index: 5;
`;
const Services = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;
const ProfileCard = styled.section`
  display: flex;
  padding: 32px 50px;
  background: #ff0000;

  width: 100%;

  color: #fff;
  margin: 30px auto 0;
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  h3 {
    font-size: 17px;
    font-weight: 400;

    span {
      font-size: 28px;
      font-weight: 700;
      @media screen and (max-width: 700px) {
        font-size: 23px;
      }
    }
  }
  button {
    padding: 20px 50px;
    background-color: #fff;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.7s linear;
    &:hover {
      background-color: #0c55e9;
      color: #fff;
    }
    @media screen and (max-width: 700px) {
      margin-top: 13px;
      padding: 20px;
    }
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  width: fit-content;
  margin-right: 30px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 30px;
    margin-right: 0;
    width: 100%;
  }
`;
const Right = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  height: fit-content;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const Button = styled.button`
  padding: 10px 30px;
  background-color: ${({ blue }) => (blue ? "#0c55E9" : "#FF0000")};
  border: none;
  @media screen and (max-width: 600px) {
    padding: 10px 15px;
  }
  width: fit-content;
  color: white;
  cursor: pointer;
  transition: all 0.7s ease-in-out;
  &:first-child {
    margin-right: 20px;
  }
  &:hover {
    border-radius: 20px;
    color: wheat;
  }
`;
const Section2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;
const Section3 = styled.section`
  display: flex;

  padding: 20px 0;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding: 40px 0;
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
const Section4 = styled.section`
  display: flex;

  padding: 20px 0;
  background: #e9e6e6;
  margin: 20px 0;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// const Card = styled.section`
//   display: flex;
//   padding: 32px 50px;
//   background: #ff0000;
//   justify-content: space-between;
//   width: 100%;
//   border-radius: 10px;
//   align-items: center;
//   color: #fff;
//   margin-bottom: 30px;
//   @media screen and (max-width: 700px) {
//     flex-direction: column;
//   }
//   h3 {
//     font-size: 17px;
//     font-weight: 400;

//     span {
//       font-size: 28px;
//       font-weight: 700;
//       @media screen and (max-width: 700px) {
//         font-size: 23px;
//       }
//     }
//   }
//   button {
//     padding: 20px 50px;
//     background-color: #fff;
//     color: #000;
//     font-size: 18px;
//     font-weight: 700;
//     border-radius: 7px;
//     cursor: pointer;
//     transition: all 0.7s linear;
//     &:hover {
//       background-color: #0c55e9;
//       color: #fff;
//     }
//     @media screen and (max-width: 700px) {
//       margin-top: 13px;
//     }
//   }
// `;

const ContactCardWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100vw;
  height: fit-content;
  position: relative;
  margin: 80px 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 1;
  }
  background: url("https://img.freepik.com/premium-photo/word-contact-us-with-globe-world-computer-mouse_165073-1107.jpg?size=626&ext=jpg")
    center/contain no-repeat;
`;
const ContactCard = styled.div`
  width: 420px;
  height: 420px;
  background-color: #0c55e9;
  transform: rotate(45deg);
  margin: 30px 0;
  padding: 15px;
  z-index: 5;
  & .border {
    width: 100%;
    height: 100%;
    border: 1px dashed lightgray;
  }
  @media screen and (max-width: 600px) {
    transform: rotate(0deg);
    width: fit-content;
    height: fit-content;
  }
`;
const ContactCardItems = styled.div`
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    transform: rotate(0deg);
    padding: 20px 20px;
  }
  a,
  h5,
  h6 {
    color: #fff;
    margin-bottom: 10px;
    font-weight: 700;
  }
  a {
    font-size: 35px;
    font-weight: 900;
    cursor: pointer;
    @media screen and (max-width: 600px) {
      font-size: 30px;
    }
  }
  h5 {
    font-size: 25px;
    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }
  h6 {
    font-size: 18px;
    @media screen and (max-width: 600px) {
      font-size: 15px;
    }
  }
`;
const Arrows = styled.section`
  display: flex;
  align-items: center;
  h1 {
    color: #ff0000;
    margin-left: 10px;
    font-size: 23px;
  }
`;
const Whatsapp = styled.div`
  display: inline-flex;
  border: 3px solid #fff;
  border-radius: 25px;
  padding: 5px;
  margin-bottom: 20px;

  transition: all 0.6s linear;
  &:hover {
    box-shadow: 3px 3px 3px #fff;
    color: #fff;
  }

  a {
    cursor: pointer;
    background: #fff;
    border-radius: 25px;
    padding: 10px 35px;
    font-size: 18px;
    height: fit-content;
    color: #000;
    margin-bottom: 0px;
    span {
      color: #0c55e9;
    }
  }
`;
const Arrow = styled.div`
  width: 20px;
  height: 20px;
  border-right: 8px solid #0c55e9;
  border-top: 8px solid #0c55e9;
  transform: rotate(45deg);
`;
const Arrow2 = styled(Arrow)`
  border-right: 8px solid #000;
  border-top: 8px solid #000;
  margin: 0px 5px 0px 0px;
`;
const Arrow3 = styled.div`
  background-color: #ff0000;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
`;
export default Home;
