import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";
import { FaLongArrowAltRight } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import { Fade, Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { HiOutlineArrowCircleRight } from "react-icons/hi";

const WhoWeAre = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const baseDir = process.env.PUBLIC_URL;
  useEffect(() => {
    document.title = "Who We Are - Netgenix Tech Icons";
  }, []);
  const images = useMemo(
    () => [
      `${baseDir}/CEO.jpg`,
      `${baseDir}/collins.jpg`,
      `${baseDir}/ed.jpg`,
      `${baseDir}/Kalani.jpeg`,
      `${baseDir}/Brian.png`,
      `${baseDir}/CTO.jpg`,
    ], // eslint-disable-next-line
    []
  );
  const items = [
    {
      id: 0,
      title:
        "Web development - Our experienced web development team specializes in both front-end and back-end development.",
    },
    {
      id: 1,
      title:
        "Mobile app development - Whether you need an iOS, Android, or cross-platform app, our team creates seamless solutions tailored to your objectives. ",
    },
    {
      id: 2,
      title:
        "Computer Aided Drawings - We excel in 2D drafting and 3D modeling, helping you visualize concepts and streamline workflows with utmost accuracy.",
    },
    {
      id: 3,
      title:
        "Product Design - We combine creativity and technical expertise to create innovative and functional designs.",
    },
    {
      id: 4,
      title:
        "Circuit Design - With our circuit design and simulation expertise, we optimize electronic systems and devices. ",
    },

    {
      id: 6,
      title:
        "IoT(Internet of Things) - Our comprehensive IoT services span across various industries, including smart buildings, healthcare, manufacturing, agriculture, and more.",
    },
    {
      id: 7,
      title:
        "Programming - We cover a wide range of programming languages and frameworks, guiding you through hands-on exercises and real-world projects.",
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);

    return () => clearInterval(interval);
  }, [images]);

  useEffect(() => {
    setTranslateX(-currentIndex * 100);
  }, [currentIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1
    );
  };
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  };
  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Who We Are" />
      </head>
      <Content>
        <Section1>
          <h1>
            <Zoom triggerOnce={true}>About Us</Zoom>
          </h1>

          <h2>
            <Zoom triggerOnce={true}>Home - Who We Are</Zoom>
          </h2>
        </Section1>
        <Title1>
          <Zoom triggerOnce={true}>WHO WE ARE</Zoom>
        </Title1>

        <Title4>
          Netgenix Tech Icons is the home of Excellent
          <span> IT Solutions </span>for all!
        </Title4>

        <Section2 className="wrapper">
          <CarouselWrapper>
            <CarouselInner style={{ transform: `translateX(${translateX}%)` }}>
              {images.map((image, index) => (
                <CarouselItem key={index}>
                  <Image
                    src={image}
                    alt={`Slide ${index + 1}`}
                    loading="lazy"
                  />
                </CarouselItem>
              ))}
            </CarouselInner>
            <Icon left onClick={handlePrevious}>
              <PiCaretLeftBold color="#fff" size={32} />
            </Icon>
            <Icon right onClick={handleNext}>
              <PiCaretRightBold color="#fff" size={32} />
            </Icon>
          </CarouselWrapper>
          <Right>
            <Zoom triggerOnce={true}>
              <Title3>
                We understand I.T Plays an important role in your business or
                your organization. That is why Netgenix was established to help
                companies and organizations like yours with world class I.T
                services that is essential in growing your business, lowering
                your cost and increasing your efficiency. <br />
                <br />
                We address the challenge of dealing with the multiple
                technologies that you require to run your business successfully
                without worrying of its support, implementation and the skills
                required.
                <br />
                <br />
                We maintain and manage all your IT services to give you peace of
                mind and save you money.
                <br />
                <br /> Netgenix Tech Icons Limited was incorporated in 2023. Our
                inception was inspired by the wide gap in the ICT industry.
              </Title3>
            </Zoom>
            <Link to="/our-team">
              <TeamBtn>
                View Our Team{" "}
                <PiCaretRightBold
                  color="#fff"
                  style={{ marginLeft: "10px", marginTop: "6px" }}
                  className="icon"
                />
              </TeamBtn>
            </Link>
          </Right>
        </Section2>
        <Section3 className="wrapper">
          <Title5>We help you with: </Title5>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            {items.map((item) => (
              <HorizontalWrapper key={item.id}>
                <FaLongArrowAltRight
                  style={{ marginRight: "20px" }}
                  color="#0c55e9"
                />
                <Title6>{item.title}</Title6>
              </HorizontalWrapper>
            ))}
          </Zoom>
        </Section3>
        <Section4>
          <Cards className="wrapper">
            <VisionCard>
              <div>
                <AiFillEye size={60} color="#fff" />
              </div>
              <h2>Vision Statement</h2>
              <h3>
                At Netgenix Tech Icons, our vision is to be the best
                Tech-Solution Centre that empowers and revolutionizes every
                Business organization to achieve their Core Goals and Objectives
              </h3>
            </VisionCard>
            <VisionCard>
              <div>
                <BsArrowUpRightCircleFill size={60} color="#fff" />
              </div>
              <h2>Mission Statement</h2>
              <h3>
                To offer Technology Solutions that; increase productivity,
                improve ease of Business daily operations, contributing to
                attainment of every organizational goals and vision.
              </h3>
            </VisionCard>
          </Cards>
          <Fade duration={1400} triggerOnce={true}>
            {/* <ProfileCard className="wrapper">
              <h3>
                <span>Want to know more about us? </span>View Our Company
                Profile...
              </h3>
              <button>
                <FaArrowRight style={{ marginRight: "10px" }} />
                View Profile
              </button>
            </ProfileCard> */}
            <ProfileCard>
              <div className="wrapper">
                <h3>
                  <span>Need a solution from us? </span> Click the button to
                  enquire for a service...
                </h3>
                <Link to={`/request-quote/Web development`}>
                  <button>
                    <HiOutlineArrowCircleRight
                      style={{ marginRight: "10px" }}
                    />
                    Request a Quote
                  </button>
                </Link>
              </div>
            </ProfileCard>
          </Fade>
        </Section4>
        <Section5>
          <Section5Content className="wrapper">
            <VerticalWrapper>
              <h4>
                <Zoom triggerOnce={true}>
                  Since our inception, our goal has been to provide top-notch
                  ICT solutions that help our clientele have smooth day-to-day
                  operations and achieve their business objectives.
                </Zoom>
              </h4>
              <br />
              <br />
              <h5>
                <Zoom triggerOnce={true}>
                  Our quality and efficient solutions have earned us a
                  reputation and recognition from many of our clients who got
                  satisfaction from our services.
                </Zoom>
              </h5>
              <h3>Our Goal</h3>
            </VerticalWrapper>
            <VerticalWrapper>
              <Zoom cascade damping={0.3} triggerOnce={true}>
                <Circle1>
                  <h1>10+</h1>
                  <h2>Professional Staff</h2>
                </Circle1>
                <Circle2>
                  <h1>200+</h1>
                  <h2>Satisfied Clients</h2>
                </Circle2>
                <Circle3>
                  <h1>15+</h1>
                  <h2>Solutions Offered</h2>
                </Circle3>
                <Circle4>
                  <h1>50+</h1>
                  <h2>Completed Projects</h2>
                </Circle4>
              </Zoom>
            </VerticalWrapper>
          </Section5Content>
        </Section5>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;
const Section1 = styled.section`
  width: 100vw;
  height: 430px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 2;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    z-index: 5;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 37px;
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
`;

const Title4 = styled.h1`
  color: #000;
  font-size: 20px;
  margin: 20px auto;
  display: inline-block;
  padding: 0 30px;
  text-align: center;
  span {
    font-size: 22px;
    color: #0c55e9;
    padding: 0 10px;
  }
`;
const Cards = styled.div`
  display: flex;

  width: 100%;
  position: relative;
  margin-bottom: 110px;
  height: fit-content;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`;

// const ProfileCard = styled.section`
//   display: flex;
//   padding: 32px 50px;
//   background: #ff0000;
//   justify-content: space-between;
//   width: 100%;
//   border-radius: 10px;
//   align-items: center;
//   color: #fff;
//   margin-bottom: 30px;
//   @media screen and (max-width: 700px) {
//     flex-direction: column;
//   }
//   h3 {
//     font-size: 17px;
//     font-weight: 400;

//     span {
//       font-size: 28px;
//       font-weight: 700;
//       @media screen and (max-width: 700px) {
//         font-size: 23px;
//       }
//     }
//   }
//   button {
//     padding: 20px 50px;
//     background-color: #fff;
//     color: #000;
//     font-size: 18px;
//     font-weight: 700;
//     border-radius: 7px;
//     cursor: pointer;
//     transition: all 0.7s linear;
//     &:hover {
//       background-color: #0c55e9;
//       color: #fff;
//     }
//     @media screen and (max-width: 700px) {
//       margin-top: 13px;
//     }
//   }
// `;
const ProfileCard = styled.section`
  display: flex;
  padding: 32px 50px;
  background: #ff0000;

  width: 100%;

  color: #fff;
  margin: 30px auto 0;
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  h3 {
    font-size: 17px;
    font-weight: 400;

    span {
      font-size: 28px;
      font-weight: 700;
      @media screen and (max-width: 700px) {
        font-size: 23px;
      }
    }
  }
  button {
    padding: 20px 50px;
    background-color: #fff;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.7s linear;
    &:hover {
      background-color: #0c55e9;
      color: #fff;
    }
    @media screen and (max-width: 700px) {
      margin-top: 13px;
      padding: 20px;
    }
  }
`;

const VisionCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 40px;
  background: #fff;
  box-shadow: 0 5px 5px #d9d9d9;
  width: 50%;
  position: absolute;
  top: 80px;
  height: fit-content;
  border-radius: 2px;
  align-items: center;
  position: relative;
  color: #000;
  cursor: pointer;
  transition: all 0.8s linear;
  @media screen and (max-width: 700px) {
    width: 90%;
    margin: auto;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 7px;
    background-color: #0c55e9;
    transform: scaleX(0);
    transition: all 1s linear;
  }
  &:hover {
    top: 100px;
    &::after {
      transform: scaleX(1);
    }
  }
  &:first-child {
    @media screen and (max-width: 700px) {
      margin-bottom: 80px;
    }
    @media screen and (min-width: 700px) {
      margin-right: 20px;
    }
  }
  h2 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 17px;
  }
  h3 {
    font-size: 19px;
    font-weight: 400;
    line-height: 37px;
    display: flex;
    text-align: center;
    padding: 0 40px;
  }
  div {
    position: absolute;
    width: 112px;
    height: 112px;
    border-radius: 50%;
    background-color: #ff0000;
    display: grid;
    place-items: center;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 30;
  }
`;
const Section4 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;

  background-color: #d9d9d9;
`;
const Section5 = styled.section`
  width: 100vw;
  height: 530px;
  position: relative;

  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat fixed;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.9)
    );
    pointer-events: none;
    z-index: 1;
  }

  @media screen and (max-width: 700px) {
    height: fit-content;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title1 = styled.h1`
  color: #000;
  font-weight: 900;
  font-size: 30px;
  margin-top: 30px;
  display: flex;
  align-self: center;
  @media screen and (max-width: 700px) {
    font-size: 24px;
  }
  span {
    color: #0c55e9;
  }
`;
const Section5Content = styled.div`
  display: flex;
  align-items: center;
  z-index: 6;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    margin: 40px 0;
  }
`;

const Right = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 50%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const TeamBtn = styled.button`
  padding: 20px 50px;
  background-color: #0c55e9;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
  width: fit-content;
  transition: all 0.7s linear;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  &:hover {
    background-color: #ff0000;
  }
`;
const Section2 = styled.div`
  display: flex;
  padding: 40px 0;
  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
  }
`;
const CarouselWrapper = styled.div`
  position: relative;
  width: 50%;
  overflow: hidden;
  margin-right: 18px;
  height: 100%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const Title3 = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 19px;
  margin-top: 10px;
  line-height: 30px;

  span {
    color: #0c55e9;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;

  width: 100%;
  align-items: center;
  margin-bottom: 15px;
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  position: relative;
  width: 50%;
  height: 100%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
  &:first-child {
    padding-left: 35px;
    margin-right: 30px;
  }
  h4 {
    font-size: 22px;
    color: #fff;
    font-weight: 600;
  }
  h5 {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
  }
  h3 {
    line-height: 35px;
    border-bottom: 5px solid #0c55e9;
    width: fit-content;
    font-size: 20px;
    transform: rotate(-90deg);
    color: #fff;
    font-weight: 400;
    letter-spacing: 3px;
    position: absolute;
    left: -50px;
    top: 30px;
    @media screen and (max-width: 700px) {
      left: -40px;
    }
  }
  &:last-child {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }
`;
const Section3 = styled.div`
  display: flex;

  width: 100%;
  padding: 30px;
  flex-direction: column;
  box-shadow: 0 5px 5px #d9d9d9;
`;
const Title5 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
`;
const Title6 = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 19px;

  line-height: 30px;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const CarouselInner = styled.div`
  display: flex;
  transition: transform 1s ease-in-out;
`;

const CarouselItem = styled.div`
  flex: 0 0 100%;
`;
const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 25px;
  /* background-color: #0c55e9; */

  cursor: pointer;
  left: ${({ left }) => left && "10px"};
  right: ${({ right }) => right && "10px"};
`;
const Circle1 = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  color: #fff;
  padding: 10px;
  position: absolute;
  top: -40px;
  right: 270px;
  h1 {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
    z-index: 7;
  }
  h2 {
    font-weight: 400;
    font-size: 18px;
    z-index: 7;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/contain;

  &::before {
    content: "";
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #0c55e9;
    pointer-events: none;
    z-index: 1;
    opacity: 1;
  }
  &:hover {
    &::before {
      animation: card 1s linear forwards;
    }
  }
  &:not(:hover) {
    &::before {
      animation: undo 1s linear forwards;
    }
  }

  @keyframes card {
    50% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.6;
    }
  }
  @keyframes undo {
    60% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;
const Circle2 = styled(Circle1)`
  width: 184px;
  height: 184px;
  position: absolute;
  top: -60px;
  right: 60px;
`;
const Circle3 = styled(Circle2)`
  width: 184px;
  height: 184px;
  position: absolute;
  top: 140px;
  right: 330px;
`;
const Circle4 = styled(Circle1)`
  position: absolute;
  top: 130px;
  right: 150px;
`;
export default WhoWeAre;
