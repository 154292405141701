import { useEffect } from "react";
import styled from "styled-components";

import { HiOutlineArrowCircleRight } from "react-icons/hi";

import { BiSolidQuoteAltRight } from "react-icons/bi";
import { Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const Testimonials = () => {
  useEffect(() => {
    document.title = "Testimonials - Netgenix Tech Icons";
  }, []);

  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Client Testimonials" />
      </head>
      <Content>
        <Section1>
          <h1>
            <Zoom triggerOnce={true}>Client Testimonials</Zoom>
          </h1>
          <h2>
            <Zoom triggerOnce={true}>Home - Testimonials</Zoom>
          </h2>
        </Section1>

        <Section2>
          <Intro className="wrapper">
            <h3>Testimonials</h3>
            <h2>
              <Zoom triggerOnce={true}>
                Here are our success stories as told by our clients.
              </Zoom>
            </h2>
            <h4>
              <Zoom triggerOnce={true}>
                We are proud to have served them and here is what they have to
                say about our services.
              </Zoom>
            </h4>
          </Intro>
          <Cards className="wrapper">
            <Zoom cascade damping={0.3} triggerOnce={true}>
              <Testimonial>
                <div>
                  <BiSolidQuoteAltRight size={60} color="rgba(0,0,0,0.5)" />
                </div>

                <h3>
                  Netgenix Tech Icons transformed our online presence with a
                  stunning website. Their team's expertise and creativity
                  exceeded our expectations. We highly recommend them!
                </h3>
                <h2>Kenya Climate Directory</h2>
                <h3>Web application</h3>
              </Testimonial>
              <Testimonial>
                <div>
                  <BiSolidQuoteAltRight size={60} color="rgba(0,0,0,0.5)" />
                </div>

                <h3>
                  Netgenix Tech Icons has been an invaluable partner in
                  safeguarding our IoT infrastructure. Their Advanced IoT
                  Security System is second to none. We highly recommend
                  Netgenix Tech Icons for anyone seeking the highest level of
                  security for their IoT ecosystem.
                </h3>
                <h2>Peleza International</h2>
                <h3>Advanced IoT security system</h3>
              </Testimonial>
              <Testimonial>
                <div>
                  <BiSolidQuoteAltRight size={60} color="rgba(0,0,0,0.5)" />
                </div>

                <h3>
                  Choosing Netgenix Tech Icons for our mobile app development
                  was the best decision we made for our business. They brought
                  our vision to life with a sleek, intuitive app that has
                  garnered rave reviews from our users.
                </h3>
                <h2>Men Workouts</h2>
                <h3>Mobile application</h3>
              </Testimonial>
            </Zoom>
          </Cards>
          <Zoom duration={2000} triggerOnce={true}>
            <ProfileCard>
              <div className="wrapper">
                <h3>
                  <span>Need a solution from us? </span> Click the button to
                  enquire for a service...
                </h3>
                <Link to={`/request-quote/Web development`}>
                  <button>
                    <HiOutlineArrowCircleRight
                      style={{ marginRight: "10px" }}
                    />
                    Request a Quote
                  </button>
                </Link>
              </div>
            </ProfileCard>
          </Zoom>
        </Section2>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;
const Section1 = styled.section`
  width: 100vw;
  height: 530px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 2;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    z-index: 5;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 37px;
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
`;

const Cards = styled.div`
  display: grid;

  width: 100%;
  position: relative;
  margin-bottom: 110px;
  margin-top: 20px;
  height: fit-content;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

const ProfileCard = styled.section`
  display: flex;
  padding: 32px 50px;
  background: #ff0000;

  width: 100%;

  color: #fff;
  margin: 30px auto 0;
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  h3 {
    font-size: 17px;
    font-weight: 400;

    span {
      font-size: 28px;
      font-weight: 700;
      @media screen and (max-width: 700px) {
        font-size: 23px;
      }
    }
  }
  button {
    padding: 20px 50px;
    background-color: #fff;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.7s linear;
    &:hover {
      background-color: #0c55e9;
      color: #fff;
    }
    @media screen and (max-width: 700px) {
      margin-top: 13px;
      padding: 20px;
    }
  }
`;

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  padding: 80px 0 40px;
  background: #fff;
  box-shadow: 0 5px 5px #d9d9d9;
  width: 100%;
  position: absolute;
  top: 80px;
  height: fit-content;
  border-radius: 2px;
  align-items: center;
  position: relative;
  color: #000;
  cursor: pointer;
  transition: all 0.8s linear;
  @media screen and (max-width: 700px) {
    width: 90%;
    margin: auto;
  }

  &:hover {
    top: 100px;
  }

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 1.5px solid #d9d9d9;
  }
  h2 {
    font-size: 23px;
    font-weight: 700;
    margin-top: 17px;
  }
  h3 {
    font-size: 19px;
    font-weight: 400;
    line-height: 37px;
    display: flex;
    text-align: center;
    padding: 0 40px;
  }
  div {
    position: absolute;
    top: -25px;
    left: 15%;

    z-index: 30;
  }
`;
const Section2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #d9d9d9;
`;

const Intro = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 80px auto 0;
  position: relative;
  grid-column-gap: 30px;
  width: 100%;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
  h3 {
    line-height: 30px;
    border-bottom: 5px solid #0c55e9;
    width: fit-content;
    font-size: 20px;
    transform: rotate(-90deg);
    color: #000;
    font-weight: 400;
    letter-spacing: 3px;
    position: absolute;
    left: -50px;

    @media screen and (max-width: 700px) {
      left: -40px;
    }
  }
  h2 {
    padding-left: 60px;
    font-weight: 700;
    font-size: 35px;
    text-align: center;
    margin-top: -40px;
    @media screen and (max-width: 700px) {
      padding-left: 35px;
      font-size: 30px;
      margin-top: -50px;
    }
  }
  h4 {
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    margin-top: -35px;
    @media screen and (max-width: 700px) {
      padding-left: 40px;
      font-size: 20px;
      margin-top: 10px;
    }
  }
`;

export default Testimonials;
