import React from "react";

import { IoCaretDownCircleSharp, IoCaretUpCircleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Service = ({ title, active, subServices, icon, onClick }) => {
  return (
    <Wrapper>
      <Content active={active} onClick={onClick}>
        <Item>
          {icon}
          <ItemTitle>{title}</ItemTitle>
        </Item>
        <Icon active={active}>
          {active ? (
            <IoCaretUpCircleSharp color="#fff" size={23} />
          ) : (
            <IoCaretDownCircleSharp color="#fff" size={23} />
          )}
        </Icon>
      </Content>
      <SubServices active={active}>
        {subServices.map((service) => (
          <div>
            <Link to={`/our-services/${title}`}>
              <SubServiceTitle key={service.id}>
                {service.title}
              </SubServiceTitle>
            </Link>
          </div>
        ))}
      </SubServices>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  padding-left: 20px;
  transition: all 0.5s linear;
  cursor: pointer;
  background: ${({ active }) => (active === true ? "#ff0000" : "#0c55e9")};
  border-bottom: 1px solid #d9d9d9;
  &:hover {
    opacity: 0.8;
  }
`;
const Icon = styled.div`
  display: grid;
  place-items: center;
  width: 70px;

  background: ${({ active }) => (active === true ? "#BE161D" : "#2160ca")};
`;
const Item = styled.div`
  padding: 23px 0;
  display: flex;
  align-items: center;
  h1 {
    color: #fff;
    font-size: 19px;
    margin-left: 6px;
  }
`;
const ItemTitle = styled.h1`
  color: #d9d9d9;
  font-size: 18px;
`;
const SubServiceTitle = styled.h1`
  color: #000;
  font-size: 16px;
  padding: 12px 0;
  font-weight: 500;
  font-family: sans-serif;
  cursor: pointer;
  transition: all 0.5s linear;
  padding-left: 20px;
  &:hover {
    opacity: 0.8;
    color: #0c55e9;
  }
`;
const SubServices = styled.div`
  display: ${({ active }) => (active === true ? "block" : "none")};
  opacity: ${({ active }) => (active === true ? "1" : "0")};
  flex-direction: column;
  transition: all 0.5s linear;
  div {
    &:not(:last-child) {
      border-bottom: 1px solid #d9d9d9;
    }
  }
`;
export default Service;
