import React from "react";

import styled from "styled-components";

const HomeCard = ({ title, icon, count, symbol }) => {
  return (
    <Wrapper>
      {icon}
      <VerticalWrapper>
        <Title>
          {count} <span>{symbol}</span>
        </Title>
        <Title2>{title}</Title2>
      </VerticalWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  box-shadow: 3px 3px 3px lightgray;
  padding: 20px 0 20px 40px;
  display: flex;
  width: 100%;
  background: #fff;
  align-items: center;
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25px;
`;
const Title = styled.h1`
  color: #000;
  font-size: 42px;
  font-weight: 900;
  display: flex;
  margin-bottom: 16px;
  align-items: flex-start;
  span {
    font-size: 30px;
    font-weight: 700;
    color: #0c55e9;
  }
`;
const Title2 = styled.h1`
  color: #000;
  font-size: 19px;
  font-weight: 400;
`;
export default HomeCard;
