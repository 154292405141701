import { useEffect } from "react";
import styled from "styled-components";
import { Zoom } from "react-awesome-reveal";
import { Link, useParams } from "react-router-dom";
import { services3 } from "../data/services";

const Services = () => {
  const params = useParams();
  const service = services3.find(
    (service1) => service1.title === params.service
  );

  useEffect(() => {
    document.title = `${service?.title} - Netgenix Tech Icons`;
  }, [service?.title]);

  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Our Services" />
      </head>
      <Content>
        <Section1 image={service?.image}>
          <h1>
            <Zoom triggerOnce={true}>{service?.title}</Zoom>
          </h1>
          <h2>
            <Zoom triggerOnce={true}>Home - Our Services</Zoom>{" "}
          </h2>
        </Section1>
        <Cards className="wrapper">
          <Left>
            <Zoom duration="1200" triggerOnce={true}>
              <Title1>{service?.title}</Title1>
            </Zoom>
            <Zoom triggerOnce={true}>
              <Title2>{service?.desc1}</Title2>
            </Zoom>
            <Zoom triggerOnce={true}>
              <Buttons>
                <Link to="/contact-us">
                  <Button blue>Contact Us</Button>
                </Link>
                <Link to={`/request-quote/${service?.title}`}>
                  <Button>Request Quote</Button>
                </Link>
              </Buttons>
            </Zoom>
          </Left>
          <Right>
            <Zoom cascade damping={0.2} triggerOnce={true}>
              {service?.subServices.map((service1, index) => (
                <Service>
                  <Label>{service1?.title}</Label>
                  <Title3>
                    <Zoom triggerOnce={true}>{service1?.desc}</Zoom>
                  </Title3>
                </Service>
              ))}
            </Zoom>
          </Right>
        </Cards>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Section1 = styled.section`
  width: 100vw;
  height: 430px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 1;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
  @media screen and (min-width: 700px) {
    height: 550px;
    background: ${({ image }) =>
      image &&
      `url(${image})
      center/contain`};
  }

  background: ${({ image }) =>
    image &&
    `url(${image})
      center/cover no-repeat`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    z-index: 5;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 37px;
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
`;
const Title1 = styled.h1`
  color: #0c55e9;
  font-weight: 900;
  font-size: 39px;
  margin-bottom: 20px;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    font-size: 24px;
  }
`;

const Title2 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
`;
const Buttons = styled.div`
  display: flex;
  z-index: 5;
  margin-top: 22px;
`;
const Button = styled.button`
  padding: 10px 30px;
  background-color: ${({ blue }) => (blue ? "#0c55E9" : "#FF0000")};
  border: none;

  width: fit-content;
  color: white;
  cursor: pointer;
  transition: all 0.7s ease-in-out;
  &:first-child {
    margin-right: 20px;
  }
  &:hover {
    border-radius: 20px;
    color: wheat;
  }
`;

const Service = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 50%;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const Left = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 50%;
  @media screen and (max-width: 800px) {
    width: 100%;

    margin-right: 0;
    margin-bottom: 15px;
  }

  margin-right: 15px;
`;

const Cards = styled.div`
  display: flex;
  padding: 40px 0;
  align-items: flex-start;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Label = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 4px;
  color: #0c55e9;
  text-transform: uppercase;
  @media screen and (max-width: 800px) {
    font-size: 15px;
  }
`;
const Title3 = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  color: #000;
`;

export default Services;
