import {
  ACTIVE_ABOUT,
  ACTIVE_SERVICE,
  ACTIVE_TAB,
  OPEN_SIDEBAR,
} from "../constants/activeTabConstants";

export const activeTabReducer = (state = {}, action) => {
  if (action.type === ACTIVE_TAB) {
    return { ...state, index: action.payload };
  } else if (action.type === ACTIVE_ABOUT) {
    return { ...state, about: action.payload };
  } else if (action.type === ACTIVE_SERVICE) {
    return { ...state, service: action.payload };
  }
  return state;
};
export const sidebarOpenReducer = (state = {}, action) => {
  if (action.type === OPEN_SIDEBAR) {
    return { ...state, open: action.payload };
  }
  return state;
};
