import { combineReducers, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import {
  activeTabReducer,
  sidebarOpenReducer,
} from "./reducers/activeTabReducers";
import { legacy_createStore } from "redux";
import { emailsReducer, requstQuoteReducer } from "./reducers/emailReducers";

const initialState = {};

const reducers = combineReducers({
  activeTab: activeTabReducer,
  sidebarOpen: sidebarOpenReducer,
  sendMail: emailsReducer,
  requestQuote: requstQuoteReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = legacy_createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
