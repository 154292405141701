import {
  ACTIVE_ABOUT,
  ACTIVE_SERVICE,
  ACTIVE_TAB,
  OPEN_SIDEBAR,
} from "../constants/activeTabConstants";

export const changeActiveTab = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_TAB, payload: index });
};
export const changeActiveAbout = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_ABOUT, payload: index });
};
export const changeActiveService = (index) => (dispatch) => {
  dispatch({ type: ACTIVE_SERVICE, payload: index });
};

export const openSidebar = (open) => (dispatch) => {
  dispatch({ type: OPEN_SIDEBAR, payload: open });
};
