import { useEffect, useState } from "react";
import styled from "styled-components";
import { BiPhone } from "react-icons/bi";
import { FaArrowAltCircleRight, FaWhatsapp } from "react-icons/fa";
import InputWithLabel from "../components/InputWithLabel";
import TextAreaWithLabel from "../components/TextAreaWithLabel";
import { Zoom } from "react-awesome-reveal";
import { socials } from "../data/socials";
import { useDispatch, useSelector } from "react-redux";
import { sendMail } from "../redux/actions/emailActions";
import { toast } from "react-toastify";

import { useIsMount } from "../hooks/useIsMount";
const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us - Netgenix Tech Icons";
  }, []);
  const items = [
    { id: 9, title: "Select Service" },
    { id: 0, title: "Web development" },
    { id: 1, title: "Mobile app development" },
    { id: 2, title: "Computer Aided Drawings" },
    { id: 3, title: "Product Design" },
    { id: 4, title: "Circuit Design" },
    { id: 5, title: "Simulations" },
    { id: 6, title: "IoT(Internet of Things)" },
    { id: 7, title: "LoRa Technology" },
    { id: 8, title: "Programming" },
  ];
  const message = encodeURIComponent("Hello there. I need your services");
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [service, setService] = useState("");
  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !body || !name || !title) {
      errorExists("Kindly fill the required fields!");
    } else {
      const messageTitle = service
        ? `${title}.\n    Service:${service}`
        : title;

      dispatch(sendMail(email, name, messageTitle, body));
    }
  };
  const { feedback, error, loading } = useSelector((state) => state.sendMail);

  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const success = (message) => {
    return toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      errorExists(error);
    }
  }, [error, isMount]);
  useEffect(() => {
    if (!isMount) {
      if (feedback) {
        success("Email sent successfully! We'll respond as soon as possible");
        setEmail("");
        setName("");
        setBody("");
        setTitle("");
      }
    }
  }, [feedback, isMount]);
  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Contact Us" />
      </head>
      <Content>
        <Section1>
          <h1>
            <Zoom triggerOnce={true}>Contact Us</Zoom>
          </h1>
          <h2>
            <Zoom triggerOnce={true}>Home - Contact Us</Zoom>
          </h2>
        </Section1>
        <Cards className="wrapper">
          <Card>
            <Header>
              <Zoom triggerOnce={true}>Contact Us</Zoom>
            </Header>
            <HorizontalWrapper>
              <Dots>
                <Zoom cascade damping={0.3} triggerOnce={true}>
                  <VerticalWrapper>
                    <Dot />
                  </VerticalWrapper>
                  <VerticalWrapper>
                    <Dot blue />
                    <Dot />
                  </VerticalWrapper>
                  <VerticalWrapper>
                    <Dot />
                    <Dot />
                    <Dot blue />
                  </VerticalWrapper>
                </Zoom>
              </Dots>
              <Zoom triggerOnce={true}>
                <Title1>
                  If you need any more information, feel free to
                  <span> contact us!</span>
                </Title1>
              </Zoom>
            </HorizontalWrapper>
            <HorizontalWrapper>
              <a href="tel:+254791480876">
                <IconWrapper>
                  <BiPhone color="#fff" size="23" />
                </IconWrapper>
              </a>
              <Zoom triggerOnce={true}>
                <VerticalWrapper>
                  <Title2>Call our office:</Title2>
                  <Title3>
                    <a href="tel:+254791480876">(+254) 791 480 876</a>
                  </Title3>
                </VerticalWrapper>
              </Zoom>
            </HorizontalWrapper>
            <HorizontalWrapper>
              <a href={`https://wa.me/+254791480876?text=${message}`}>
                <IconWrapper>
                  <FaWhatsapp color="#fff" size="23" />
                </IconWrapper>
              </a>
              <Zoom triggerOnce={true}>
                <VerticalWrapper>
                  <Title2>Reach us via Whatsapp:</Title2>
                  <h6>(Click below to text)</h6>
                  <Title3>
                    <a href={`https://wa.me/+254791480876?text=${message}`}>
                      (+254) 791 480 876
                    </a>
                  </Title3>
                </VerticalWrapper>
              </Zoom>
            </HorizontalWrapper>
            <Socials>
              <Zoom cascade damping={0.3} triggerOnce={true}>
                {socials.map((social, i) => (
                  <SocialIconWrapper
                    href={social.url}
                    target="_blank"
                    key={i}
                    prop={social.prop}
                  >
                    <Zoom triggerOnce={true}>{social.icon}</Zoom>
                  </SocialIconWrapper>
                ))}
              </Zoom>
            </Socials>
          </Card>
          <Card>
            <Title3>
              For any inquiries relating to our services, submit your details
              and we’ll be in touch shortly.
            </Title3>
            <InputsWrapper>
              <Zoom cascade damping={0.3} triggerOnce={true}>
                <InputWithLabel
                  label="Full Name (required)"
                  placeholder="Enter your name*"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <InputWithLabel
                  label="Email Address (required)"
                  placeholder="Enter your email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </Zoom>
            </InputsWrapper>
            <InputsWrapper>
              <Zoom cascade damping={0.3} triggerOnce={true}>
                <InputWithLabel
                  label="Message title (required)"
                  placeholder="Enter your message's title."
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                  type="text"
                />
                <ServiceSelect>
                  <Label>Select Service (optional)</Label>
                  <SelectWrapper>
                    <Select
                      onChange={(e) => setService(e.target.value)}
                      value={service}
                    >
                      {items.map((item) => (
                        <option key={item.id} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </Select>
                  </SelectWrapper>
                </ServiceSelect>
              </Zoom>
            </InputsWrapper>
            <Zoom triggerOnce={true}>
              <TextAreaWithLabel
                label="Additional Information (required)"
                placeholder="Service Description"
                rows="7"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </Zoom>
            <button
              disabled={loading || !email || !body || !name || !title}
              onClick={handleClick}
            >
              {loading ? "Sending ....." : "Send Message"}
              <FaArrowAltCircleRight
                color="#fff"
                style={{ marginLeft: "10px", marginTop: "6px" }}
              />
            </button>
          </Card>
        </Cards>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Section1 = styled.section`
  width: 100vw;
  height: 430px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 1;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    z-index: 5;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 37px;
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
`;
const Title1 = styled.h1`
  color: #000;
  font-weight: 900;
  font-size: 39px;
  margin-top: -10px;
  @media screen and (max-width: 800px) {
    font-size: 28px;
  }
  span {
    color: #0c55e9;
  }
`;
const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-bottom: 4px;
  background: ${({ blue }) => (blue ? "#0c55e9" : "#000")};
  @media screen and (max-width: 800px) {
    width: 13px;
    height: 13px;
  }
`;
const Card = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 50%;
  @media screen and (max-width: 800px) {
    width: 100%;
    &:first-child {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  &:first-child {
    margin-right: 15px;
  }
  button {
    padding: 20px 0;
    background-color: #0c55e9;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    width: 100%;
    transition: all 0.7s linear;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 800px) {
      font-size: 15px;
      padding: 12px 0;
    }
    &:hover {
      background-color: #ff0000;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const Cards = styled.div`
  display: flex;
  padding: 40px 0;
  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;
const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  background-color: #0c55e9;
  border-radius: 50%;
  margin-right: 35px;
  @media screen and (max-width: 800px) {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
`;

const Title2 = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 25px;
  margin-top: -10px;
  @media screen and (max-width: 800px) {
    font-size: 20px;
  }
  span {
    color: #0c55e9;
  }
`;
const Title3 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 19px;
  margin-top: 10px;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 17px;
  }
  span {
    color: #0c55e9;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;

  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;
`;
const InputsWrapper = styled.div`
  display: grid;

  width: 100%;
  grid-column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  h6 {
    margin-top: 3px;
  }
`;

const SelectWrapper = styled.div`
  background: rgba(217, 217, 217, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 0px 20px;
  border-radius: 1px;

  display: flex;
  align-self: flex-start;
`;
const Select = styled.select`
  width: 100%;
  padding: 17px 0px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
  option {
    color: #000;
  }
`;
const Label = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
  color: #000;
`;
const ServiceSelect = styled.div`
  display: flex;
  margin-right: 20px;
  margin-top: 13px;
  margin-right: 10px;
  flex-direction: column;
`;
const Dots = styled.div`
  display: flex;
  margin-right: 20px;
`;
const Header = styled.h3`
  color: #0c55e9;
  font-size: 18px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 5px;
    background: #0c55e9;
    pointer-events: none;

    z-index: 1;
  }
`;
const Socials = styled.div`
  display: flex;
  padding: 20px 0 20px 50px;
`;
const SocialIconWrapper = styled.a`
  display: grid;
  place-items: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  color: #fff;
  background-color: ${({ prop }) =>
    prop === "one"
      ? "#1884BC"
      : prop === "two"
      ? "#000"
      : prop === "three"
      ? "#c82755"
      : prop === "four"
      ? "#4E69A2"
      : "#33AAF3"};
  border-radius: 50%;
  margin-right: 10px;
  @media screen and (max-width: 800px) {
    width: 40px;
    height: 40px;
  }
`;
export default Contact;
