import { FaPeopleArrows } from "react-icons/fa";
import { MdGroups } from "react-icons/md";
import { BsEmojiSmileFill } from "react-icons/bs";
import { DiCodeigniter } from "react-icons/di";

export const cardsData = [
  {
    id: 0,
    title: "Satisfied Clients",
    icon: <FaPeopleArrows size={40} color="#0c55e9" />,
    count: 200,
    symbol: "+",
  },
  {
    id: 1,
    title: "Customer Satisfaction",
    icon: <BsEmojiSmileFill size={40} color="#0c55e9" />,
    count: 100,
    symbol: "%",
  },
  {
    id: 2,
    title: "Solutions Offered",
    icon: <DiCodeigniter size={40} color="#0c55e9" />,
    count: 15,
    symbol: "+",
  },
  {
    id: 3,
    title: "Qualified Staff",
    icon: <MdGroups size={40} color="#0c55e9" />,
    count: 10,
    symbol: "+",
  },
];
