import "./App.css";
import {
  Route,
  Routes,
  HashRouter as Router,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import NavbarOne from "./components/NavbarOne";
import NavbarTwo from "./components/NavbarTwo";
import Sidebar from "./components/Sidebar";
import Contact from "./pages/Contact";
import { useEffect } from "react";
import Footer from "./components/Footer";
import OurTeam from "./pages/OurTeam";
import WhoWeAre from "./pages/WhoWeAre";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changeActiveAbout,
  changeActiveTab,
  changeActiveService,
} from "./redux/actions/activeTabActions";
import { useDispatch } from "react-redux";
import Testimonials from "./pages/Testimonials";
import Services from "./pages/Services";
import RequestQuote from "./pages/RequestQuote";
import Profile from "./pages/Profile";
const MyComponent = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        dispatch(changeActiveTab(0));
        break;
      case "/who-we-are":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(0));
        break;
      case "/our-team":
        dispatch(changeActiveTab(1));
        dispatch(changeActiveAbout(1));
        break;
      case "/contact-us":
        dispatch(changeActiveTab(4));
        break;
      case "/testimonials":
        dispatch(changeActiveTab(3));
        break;

      default:
        dispatch(changeActiveTab(0));
        break;
    }
    if (location.pathname.startsWith("/our-services")) {
      dispatch(changeActiveTab(2));
      switch (decodeURIComponent(location.pathname.split("/")[2])) {
        case "Web development":
          dispatch(changeActiveService(0));
          break;
        case "Mobile app development":
          dispatch(changeActiveService(1));
          break;
        case "Computer Aided Drawings":
          dispatch(changeActiveService(2));
          break;
        case "Product Design":
          dispatch(changeActiveService(3));
          break;
        case "Circuit Design":
          dispatch(changeActiveService(4));
          break;
        case "Simulations":
          dispatch(changeActiveService(5));
          break;
        case "IoT(Internet of Things)":
          dispatch(changeActiveService(6));
          break;
        case "LoRa Technology":
          dispatch(changeActiveService(7));
          break;
        case "Programming":
          dispatch(changeActiveService(8));
          break;
        default:
          break;
      }
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname, dispatch]);
  return (
    <>
      <NavbarOne />
      <NavbarTwo />
      <Sidebar />

      <Routes>
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/our-team" element={<OurTeam />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/" element={<Home />} />
        <Route path="/request-quote/:service" element={<RequestQuote />} />
        <Route path="/our-services/:service" element={<Services />} />
        <Route path="/our-profile" element={<Profile />} />
      </Routes>
      <Footer />
    </>
  );
};
function App() {
  return (
    <Router>
      <ToastContainer />
      <MyComponent />
    </Router>
  );
}

export default App;
