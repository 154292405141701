import React from "react";
import styled from "styled-components";
const TextAreaWithLabel = ({
  label,
  placeholder,
  required,
  onChange,
  rows,
  value,
}) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        value={value}
        rows={rows}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: 12px;
`;
const Label = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 14px;
  color: #000;
`;
const Input = styled.textarea`
  background: rgba(217, 217, 217, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5.10855px;
  padding: 16px;

  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  color: #07174b;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: rgba(0, 0, 0, 0.48);
  }
`;
export default TextAreaWithLabel;
