import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";

export const socials = [
  {
    icon: <FaFacebookF size={20} />,
    url: "https://www.facebook.com/netgenixicons",
    prop: "four",
  },
  {
    icon: <FaInstagram size={20} />,
    url: "https://www.instagram.com/netgenixicons",
    prop: "three",
  },

  {
    icon: <FaLinkedinIn size={20} />,
    url: "https://www.linkedin.com/company/netgenix-icons",
    prop: "one",
  },
  {
    icon: <FaTiktok size={20} />,
    url: "https://tiktok.com/@netgenixicons",
    prop: "two",
  },
];
