import { useEffect, useState } from "react";
import styled from "styled-components";
import { BiPhone } from "react-icons/bi";
import { FaArrowAltCircleRight, FaWhatsapp } from "react-icons/fa";
import InputWithLabel from "../components/InputWithLabel";
import TextAreaWithLabel from "../components/TextAreaWithLabel";
import { AiOutlineMail } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { requestQuote } from "../redux/actions/emailActions";
import { useIsMount } from "../hooks/useIsMount";

const RequestQuote = () => {
  const params = useParams();
  const [service, setService] = useState(decodeURIComponent(params.service));
  useEffect(() => {
    document.title = `${service} - Netgenix Tech Icons`;
  }, [service]);
  const items = [
    { id: 0, title: "Web development" },
    { id: 1, title: "Mobile app development" },
    { id: 2, title: "Computer Aided Drawings" },
    { id: 3, title: "Product Design" },
    { id: 4, title: "Circuit Design" },
    { id: 5, title: "Simulations" },
    { id: 6, title: "IoT(Internet of Things)" },
    { id: 7, title: "LoRa Technology" },
    { id: 8, title: "Programming" },
  ];
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const [email, setEmail] = useState("");

  const [body, setBody] = useState("");

  const dispatch = useDispatch();

  const handleClick = (e) => {
    e.preventDefault();
    if (!email || !body || !name || !number) {
      errorExists("Kindly fill the required fields!");
    } else {
      const messageTitle = `Service:${service}`;

      dispatch(requestQuote(email, name, number, messageTitle, body));
    }
  };
  const { feedback, error, loading } = useSelector(
    (state) => state.requestQuote
  );

  const errorExists = (error) => {
    return toast.error(error, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const success = (message) => {
    return toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      errorExists(error);
    }
  }, [error, isMount]);
  useEffect(() => {
    if (!isMount) {
      if (feedback) {
        success("Email sent successfully! We'll respond as soon as possible");
        setEmail("");
        setName("");
        setBody("");
        setNumber("");
      }
    }
  }, [feedback, isMount]);
  return (
    <Wrapper>
      <head>
        <meta name="keywords" content="Request Quote" />
      </head>
      <Content>
        <Section1>
          <h1>
            <Zoom triggerOnce={true}>{service}</Zoom>
          </h1>
          <h2>
            <Zoom triggerOnce={true}>Home - Request Quote</Zoom>
          </h2>
        </Section1>
        <Section2 className="wrapper">
          <Card>
            <Zoom cascade damping={0.07} triggerOnce={true}>
              <Header>Request Quote</Header>
              <HorizontalWrapper>
                <a href="tel:+254791480876">
                  <IconWrapper>
                    <BiPhone color="#fff" size="23" />
                  </IconWrapper>
                </a>
                <Title3>
                  <a href="tel:+254791480876">(+254) 791 480 876</a>
                </Title3>
              </HorizontalWrapper>
              <HorizontalWrapper>
                <a href="mailto:info@netgenixicons.com">
                  <IconWrapper>
                    <AiOutlineMail color="#fff" size="23" />
                  </IconWrapper>
                </a>
                <Title3>
                  <a href="mailto:info@netgenixicons.com">
                    info@netgenixicons.com
                  </a>
                </Title3>
              </HorizontalWrapper>
              <HorizontalWrapper>
                <a href={`https://wa.me/+254791480876`}>
                  <IconWrapper>
                    <FaWhatsapp color="#fff" size="23" />
                  </IconWrapper>
                </a>
                <Title3>
                  <a href={`https://wa.me/+254791480876`}>(+254) 791 480 876</a>
                </Title3>
              </HorizontalWrapper>

              <Title2>
                We're all ears! We eagerly await your thoughts on how we can be
                of assistance. Kindly take a moment to complete the form below,
                and we'll swiftly respond to your inquiry—speed is our forte!.
              </Title2>
              <InputsWrapper>
                <InputWithLabel
                  label="Full Name (required)"
                  placeholder="Enter your name*"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <InputWithLabel
                  label="Email Address (required)"
                  placeholder="Enter your email*"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </InputsWrapper>
              <InputsWrapper>
                <InputWithLabel
                  label="Phone Number (required)"
                  placeholder="Enter your phone number*"
                  type="tel"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
                <ServiceSelect>
                  <Label>Select Service (required)</Label>
                  <SelectWrapper>
                    <Select
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                    >
                      {items.map((item) => (
                        <option key={item.id} value={item.title}>
                          {item.title}
                        </option>
                      ))}
                    </Select>
                  </SelectWrapper>
                </ServiceSelect>
              </InputsWrapper>
              <TextAreaWithLabel
                label="Additional Information (required)"
                placeholder="Service Description"
                rows="7"
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
            </Zoom>
            <button
              disabled={loading || !email || !body || !name || !number}
              onClick={handleClick}
            >
              {loading ? "Hold On ....." : "Request Quote"}
              <FaArrowAltCircleRight
                color="#fff"
                style={{ marginLeft: "10px", marginTop: "6px" }}
              />
            </button>
          </Card>
        </Section2>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Section2 = styled.section`
  width: 100vw;
  height: fit-content;
  margin: 30px auto;
`;
const Section1 = styled.section`
  width: 100vw;
  height: 430px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 1;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    z-index: 5;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 37px;
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
`;

const Card = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;

  width: 50%;
  @media screen and (max-width: 800px) {
    width: 100%;
    &:first-child {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  &:first-child {
    margin-right: 15px;
  }
  button {
    padding: 20px 0;
    background-color: #0c55e9;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    width: 100%;
    transition: all 0.7s linear;
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 800px) {
      font-size: 15px;
      padding: 12px 0;
    }
    &:hover {
      background-color: #ff0000;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  width: 50px;
  height: 50px;
  background-color: #0c55e9;
  border-radius: 50%;
  margin-right: 35px;
  @media screen and (max-width: 800px) {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
`;

const Title2 = styled.h1`
  color: #000;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  @media screen and (max-width: 800px) {
    font-size: 16px;
  }
  span {
    color: #0c55e9;
  }
`;
const Title3 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 19px;
  margin-top: 10px;
  cursor: pointer;
  @media screen and (max-width: 800px) {
    font-size: 17px;
  }
  span {
    color: #0c55e9;
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;

  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;
`;
const InputsWrapper = styled.div`
  display: grid;

  width: 100%;
  grid-column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

const SelectWrapper = styled.div`
  background: rgba(217, 217, 217, 0.16);
  border: 1px solid rgba(0, 0, 0, 0.7);
  width: 100%;
  padding: 0px 20px;
  border-radius: 1px;

  display: flex;
  align-self: flex-start;
`;
const Select = styled.select`
  width: 100%;
  padding: 17px 0px;
  background-color: transparent;
  border: none;
  &:focus {
    outline: none;
  }
  option {
    color: #000;
  }
`;
const Label = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
  color: #000;
`;
const ServiceSelect = styled.div`
  display: flex;
  margin-right: 20px;
  margin-top: 13px;
  margin-right: 10px;
  flex-direction: column;
`;
const Header = styled.h3`
  color: #0c55e9;
  font-size: 18px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 5px;
    background: #0c55e9;
    pointer-events: none;

    z-index: 1;
  }
`;

export default RequestQuote;
