import styled from "styled-components";

import { PiCaretRightBold } from "react-icons/pi";
import { footerItems1, footerItems2, footerItems3 } from "../data/footerItems";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveAbout,
  changeActiveService,
  changeActiveTab,
} from "../redux/actions/activeTabActions";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";
import { socials } from "../data/socials";
const Footer = () => {
  const activeIndex = useSelector((state) => state.activeTab);

  const dispatch = useDispatch();
  const handleClick = (index) => {
    dispatch(changeActiveTab(index));
    dispatch(changeActiveAbout(null));
    dispatch(changeActiveService(null));
  };
  const handleAboutClick = (index) => {
    dispatch(changeActiveTab(1));
    dispatch(changeActiveAbout(index));
    dispatch(changeActiveService(null));
  };
  const handleServiceClick = (index) => {
    dispatch(changeActiveTab(2));
    dispatch(changeActiveService(index));
    dispatch(changeActiveAbout(null));
  };

  return (
    <Wrapper>
      <Content className="wrapper">
        <VerticalWrapper>
          <SectionTitle>Contact Info</SectionTitle>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            {footerItems1.map((item) => (
              <ListItem1 key={item.id}>
                {item.icon} {item.title}
              </ListItem1>
            ))}
          </Zoom>
        </VerticalWrapper>
        <VerticalWrapper>
          <SectionTitle>ICT Solutions</SectionTitle>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            {footerItems2.map((item, index) => (
              <Link to={`/our-services/${item.title}`}>
                <ListItem2
                  key={item.id}
                  active={index === activeIndex.service}
                  onClick={() => handleServiceClick(index)}
                >
                  <PiCaretRightBold style={{ marginRight: "13px" }} />
                  <h1>{item.title}</h1>
                </ListItem2>
              </Link>
            ))}
          </Zoom>
        </VerticalWrapper>
        <VerticalWrapper>
          <SectionTitle>Quick Links</SectionTitle>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            {footerItems3.slice(0, 2).map((item, index) => (
              <Link to={item.url}>
                <ListItem2
                  key={item.id}
                  onClick={() => handleAboutClick(index)}
                  active={index === activeIndex.about}
                >
                  <PiCaretRightBold style={{ marginRight: "13px" }} />
                  <h1>{item.title}</h1>
                </ListItem2>
              </Link>
            ))}
            {footerItems3.slice(2, footerItems3.length).map((item) => (
              <Link to={item.url}>
                <ListItem2
                  key={item.id}
                  active={item.id === (activeIndex.index || 0)}
                  onClick={() => handleClick(item.id)}
                >
                  <PiCaretRightBold style={{ marginRight: "13px" }} />
                  <h1>{item.title}</h1>
                </ListItem2>
              </Link>
            ))}
          </Zoom>
        </VerticalWrapper>
        <VerticalWrapper>
          <SectionTitle>SUBSCRIBE US</SectionTitle>
          <Zoom cascade damping={0.3} triggerOnce={true}>
            <SubscribeIntro>
              Sign up to our Newsletter to receive great special offers and
              deals every month
            </SubscribeIntro>
            <Input type="email" placeholder="Your email address" />
            <Button>Subscribe</Button>
          </Zoom>
        </VerticalWrapper>
      </Content>
      <BottomWrapper>
        <Bottom className="wrapper">
          <Left>
            <Zoom triggerOnce={true}>
              <Title>
                &copy; {new Date().getFullYear()} Netgenix Tech Icons | All
                Rights Reserved.
              </Title>
            </Zoom>
          </Left>
          <Right>
            {socials.map((social, i) => (
              <Social href={social.url} target="_blank" key={i}>
                <Zoom triggerOnce={true}>{social.icon}</Zoom>
              </Social>
            ))}
          </Right>
        </Bottom>
      </BottomWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100vw;
`;

const Content = styled.div`
  padding-top: 45px;
  display: grid;
  color: #000;
  margin-bottom: 20px;
  grid-row-gap: 20px;

  grid-template-columns: repeat(4, 1fr);
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
const BottomWrapper = styled.div`
  width: 100vw;

  height: fit-content;
  background: #0c55e9;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;
const Left = styled.div`
  display: flex;
`;
const Right = styled.div`
  display: flex;
`;
const Social = styled.a`
  &:not(:last-child) {
    margin-right: 12px;
  }
  color: #fff;
  transition: all 0.5s linear;
  &:hover {
    opacity: 0.8;
    color: #ff0000;
  }
`;
const Title = styled.h1`
  color: #fff;
  font-size: 20px;
  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
    font-size: 14px;
  }
`;
const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const SectionTitle = styled.h1`
  font-size: 21px;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
  width: fit-content;
  @media screen and (max-width: 800px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 37%;
    height: 5px;
    background-color: #0c55e9;
    border-radius: 1px;
    &:not(:first-child) {
      width: 27%;
    }
  }
`;
const ListItem1 = styled.h1`
  font-size: 15px;
  padding: 7px 0;
  display: flex;
  color: #666666;
  align-items: center;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;
const ListItem2 = styled.div`
  padding: 7px 0;
  display: flex;
  color: #666666;
  align-items: center;
  transition: all 0.6s linear;
  color: ${(props) => (props.active ? "#0c55e9" : "#000")};
  margin-left: ${(props) => (props.active ? "7px" : "0")};

  h1 {
    font-size: 15px;
    border-bottom: ${(props) => (props.active ? "1px solid #0c55e9" : "none")};
    @media screen and (max-width: 800px) {
      font-size: 13px;
    }
  }
  &:hover {
    margin-left: 7px;
    color: #0c55e9;
    h1 {
      border-bottom: 1px solid #0c55e9;
    }
  }
`;
const SubscribeIntro = styled.h3`
  display: flex;
  font-size: 15px;
  margin: 10px 0;
  color: #666666;
`;
const Input = styled.input`
  width: 100%;
  margin: 16px 0;
  padding: 15px;
  color: #666666;
  font-size: 17px;
`;
const Button = styled.button`
  padding: 15px 40px;
  color: #fff;
  font-weight: 700;
  font-size: 17px;
  background-color: #0c55e9;
  width: fit-content;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.7s linear;
  &:hover {
    background-color: #ff0000;
    color: #fff;
  }
`;
export default Footer;
