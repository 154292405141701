import React from "react";

import { BiPhone } from "react-icons/bi";
import { AiOutlineMail } from "react-icons/ai";
import styled from "styled-components";
import { socials } from "../data/socials";
import { Zoom } from "react-awesome-reveal";

const NavbarOne = () => {
  return (
    <Wrapper>
      <Content className="wrapper">
        <Left>
          <LeftItem>
            <AiOutlineMail color="#fff" />
            <a href="mailto:info@netgenixicons.com">info@netgenixicons.com</a>
          </LeftItem>
          <LeftItem2>
            <BiPhone color="#fff" />
            <a href="tel:+254791480876">+254 791480876</a>
          </LeftItem2>
        </Left>
        <Right>
          {socials.map((social, i) => (
            <Social href={social.url} target="_blank" key={i}>
              <Zoom>{social.icon}</Zoom>
            </Social>
          ))}
        </Right>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.nav`
  width: 100vw;

  height: 50px;
  background: #0c55e9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1150px) {
    display: none;
  }
`;
const Left = styled.div`
  display: flex;
`;
const Right = styled.div`
  display: flex;
`;
const LeftItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
  position: relative;
  a {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-left: 6px;
  }
`;

// const Contact = styled.div`
//   display: flex;
//   flex-direction: column;
//   cursor: default;
//   background-color: #fff;
//   box-shadow: 0 3px 5px #082032;
//   z-index: 100;
//   padding: 10px 0;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 51px;
//   display: none;
//   .item {
//     display: flex;
//     align-items: center;
//     padding: 0 3px;
//     width: 100%;
//     justify-content: center;
//     &:first-child {
//       border-bottom: 1px solid #0c55e9;
//     }
//     cursor: pointer;
//     h3 {
//       font-size: 14px;
//       color: #000;
//     }
//   }
// `;
const LeftItem2 = styled(LeftItem)`
  &:hover {
    /* &Contact {
      display: block;
    } */
  }
`;
const Social = styled.a`
  &:not(:last-child) {
    margin-right: 12px;
  }
  color: #fff;
  transition: all 0.5s linear;
  &:hover {
    opacity: 0.8;
    color: #ff0000;
  }
`;
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default NavbarOne;
