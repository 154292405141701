import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Service2 = ({ title, desc1, desc2, image }) => {
  return (
    <Wrapper image={image}>
      <ItemTitle>{title}</ItemTitle>
      <ItemDesc>{desc1}</ItemDesc>
      <Wrapper2>
        <ItemTitle>{title}</ItemTitle>
        <ItemDesc>{desc2}</ItemDesc>
        <Link to={`/our-services/${title}`}>
          <Button>More Info</Button>
        </Link>
      </Wrapper2>
    </Wrapper>
  );
};

const Wrapper2 = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #0c55e9;
  transform: scale(0);
  transition: all 1.3s ease-in-out;
  cursor: default;
`;
const Wrapper = styled.div`
  background: url(${({ image }) => image}) center/contain;
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  cursor: pointer;
  &:hover {
    ${Wrapper2} {
      transform: scale(1);
    }
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.8)
    );
    pointer-events: none;
    z-index: 1;
  }
`;
const ItemTitle = styled.h1`
  color: #d9d9d9;
  font-size: 18px;
  font-style: bold;
  z-index: 5;
  margin-bottom: 20px;
`;
const ItemDesc = styled.h1`
  color: #d9d9d9;
  font-size: 15px;
  z-index: 5;
  padding: 0 30px;
`;

const Button = styled.button`
  padding: 10px 30px;
  background-color: transparent;
  border: 3px solid #fff;
  border-radius: 3px;
  width: fit-content;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  font-weight: 600;
  transition: all 0.6s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #0c55e9;
  }
`;
export default Service2;
