import React, { useEffect } from "react";
import styled from "styled-components";
import { BiSolidChevronRight } from "react-icons/bi";

import { Fade, Zoom } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import { HiOutlineArrowCircleRight } from "react-icons/hi";

const OurTeam = () => {
  useEffect(() => {
    document.title = "Our team - Netgenix Tech Icons";
  }, []);
  const baseDir = process.env.PUBLIC_URL;
  const ceos = [
    {
      name: "Antone Orwa",
      title: "Founder & CEO",
      image: `${baseDir}/CEO.jpg`,
      desc: "As the visionary Founder and CEO of Netgenix, Antone is committed to driving innovation and delivering cutting-edge IT solutions that empower businesses to thrive in the digital age. ",
    },
    {
      name: "Frandel Wanjawa",
      title: "Chief Technology Officer",
      image: `${baseDir}/CTO.jpg`,
      desc: "Working alongside our ingenious CEO and a team of dedicated developers to ensure that we not only meet your specifications today but also in posterity. Putting all the needed effort to innovate, ideate and create. Frandel is the brain that is at the epicenter of making this happen.",
    },
  ];
  const team = [
    {
      name: "Collins Bett",
      title: "Product Design Lead",
      image: `${baseDir}/collins.jpg`,
    },
    {
      name: "Edmund Munene",
      title: "IoT Lead",
      image: `${baseDir}/ed.jpg`,
    },
    {
      name: "Elvis Kalani",
      title: "Developer",
      image: `${baseDir}/Kalani.jpeg`,
    },
    {
      name: "Brian Bett",
      title: "Developer",
      image: `${baseDir}/Brian.png`,
    },
  ];
  return (
    <Wrapper>
      {" "}
      <head>
        <meta name="keywords" content="Our Team" />
      </head>
      <Content>
        <Section1>
          <h1>
            <Zoom triggerOnce={true}>About Us</Zoom>
          </h1>
          <h2>
            <Zoom triggerOnce={true}>Home - About Us</Zoom>
          </h2>
        </Section1>
        <Cards className="wrapper">
          <Card>
            <Header>
              <Zoom triggerOnce={true}>Team members</Zoom>
            </Header>

            <HorizontalWrapper>
              <Dots>
                <Zoom cascade damping={0.3} triggerOnce={true}>
                  <VerticalWrapper>
                    <Dot />
                  </VerticalWrapper>
                  <VerticalWrapper>
                    <Dot blue />
                    <Dot />
                  </VerticalWrapper>
                  <VerticalWrapper>
                    <Dot />
                    <Dot />
                    <Dot blue />
                  </VerticalWrapper>
                </Zoom>
              </Dots>
              <Zoom triggerOnce={true}>
                <Title1>
                  The Brains Behind <br />
                  Netgenix
                </Title1>
              </Zoom>
            </HorizontalWrapper>

            <Zoom triggerOnce={true}>
              <Title2>
                You've heard of us. But what about Netgenix's creators? What are
                the cogs and gears that fuel Netgenix's innovation? Building and
                supporting the finest products in the market requires the best
                team.
                <br />
                <br /> Every member of our team adds a distinct set of talents
                and experience to the company, and while we are a diverse group,
                we all share the same passion and enthusiasm for developing
                groundbreaking solutions that satisfy your needs.
              </Title2>
            </Zoom>
            <Zoom triggerOnce={true}>
              <Link to="/contact-us">
                <ContactBtn>
                  Contact Us{" "}
                  <BiSolidChevronRight
                    color="#fff"
                    style={{ marginLeft: "10px", marginTop: "4px" }}
                  />
                </ContactBtn>
              </Link>
            </Zoom>
          </Card>
          <Zoom cascade damping={0.2} triggerOnce={true}>
            {ceos.map((ceo) => (
              <Person>
                <Zoom triggerOnce={true} style={{ width: "100%" }}>
                  <Image src={ceo.image} alt="CEO" loading="lazy" />
                </Zoom>
                <Zoom triggerOnce={true}>
                  <Name>{ceo.name}</Name>
                </Zoom>
                <Zoom triggerOnce={true}>
                  <Position>{ceo.title}</Position>
                </Zoom>
                <section>
                  <h2>{ceo.desc}</h2>
                </section>
              </Person>
            ))}
          </Zoom>
        </Cards>
        <Team className="wrapper">
          <Zoom cascade damping={0.2} triggerOnce={true}>
            {team.map((member, index) => (
              <Person2 key={index}>
                <img src={member.image} alt={member.name} loading="lazy" />
                <Zoom triggerOnce={true}>
                  <Name>{member.name}</Name>
                </Zoom>
                <Zoom triggerOnce={true}>
                  <Position>{member.title}</Position>
                </Zoom>
              </Person2>
            ))}
          </Zoom>
        </Team>
        <Fade duration={1300} triggerOnce={true}>
          {/* <ProfileCard>
            <div className="wrapper">
              <h3>
                <span>Want to know more about us? </span>View Our Company
                Profile...
              </h3>
              <button>
                <FaArrowRight style={{ marginRight: "10px" }} />
                View Profile
              </button>
            </div>
          </ProfileCard> */}{" "}
          <ProfileCard>
            <div className="wrapper">
              <h3>
                <span>Need a solution from us? </span> Click the button to
                enquire for a service...
              </h3>
              <Link to={`/request-quote/Web development`}>
                <button>
                  <HiOutlineArrowCircleRight style={{ marginRight: "10px" }} />
                  Request a Quote
                </button>
              </Link>
            </div>
          </ProfileCard>
        </Fade>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Section1 = styled.section`
  width: 100vw;
  height: 430px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.7)
    );
    pointer-events: none;
    z-index: 1;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
  @media screen and (min-width: 700px) {
    height: 530px;
    background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
      center/contain;
  }
  background: url("https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg")
    center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1,
  h2 {
    color: #fff;
    font-size: 30px;
    z-index: 5;
    @media screen and (max-width: 700px) {
      font-size: 24px;
    }
  }
  h1 {
    font-size: 37px;
    margin-bottom: 10px;
    @media screen and (max-width: 700px) {
      font-size: 30px;
    }
  }
`;
const Title1 = styled.h1`
  color: #000;
  font-weight: 600;
  font-size: 33px;
  line-height: 45px;
  margin-top: -10px;
  span {
    color: #0c55e9;
  }
`;
const Dot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-bottom: 4px;
  background: ${({ blue }) => (blue ? "#0c55e9" : "#000")};
  @media screen and (max-width: 800px) {
    width: 13px;
    height: 13px;
  }
`;
const Card = styled.div`
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 5px #d9d9d9;
  background-color: #fff;
  width: 100%;
  margin-right: 20px !important;
`;

const ContactBtn = styled.div`
  padding: 20px 60px;
  background-color: #0c55e9;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  cursor: pointer;
  width: fit-content;
  transition: all 0.7s linear;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  text-align: center;
  &:hover {
    background-color: #000;
  }
`;
const Cards = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 15px;
  padding: 40px 0;
  height: fit-content;
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const Team = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  padding: 40px 0;
  height: fit-content;
  @media screen and (max-width: 1050px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Title2 = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 24.5px;
  margin-top: 10px;
  @media screen and (max-width: 700px) {
    font-size: 18px;
  }
`;

const Position = styled.h1`
  color: #000;
  font-weight: 400;
  font-size: 22px;
  transition: all 0.6s linear;
  transform: scale(1);
`;
const Person = styled.div`
  width: 100%;
  height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  padding: 4px 4px 35px;
  align-items: center;
  position: relative;
  section {
    position: absolute;
    bottom: 20%;
    left: 2%;
    width: 96%;
    transition: all 0.6s linear;
    height: fit-content;
    padding: 20px;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.2)
    );

    overflow: hidden;
    pointer-events: none;
    z-index: 10;
    transform: scale(0);
    h2 {
      z-index: 12;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      padding: 0 15px;
    }
  }
  &:hover {
    ${Position} {
      transform: scale(0);
    }

    section {
      transform: scale(1);
    }
  }
`;
const Person2 = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 7px gray;
  img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: scale-down;
    border: 4px solid #0c55e9;
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  flex: 1;
  @media screen and (max-width: 700px) {
    height: 400px;
    object-fit: cover;
    flex: none;
  }
`;

const Name = styled.h1`
  color: #000;
  font-weight: 700;
  font-size: 25px;
  margin: 14px 0;
`;

// const ProfileCard = styled.section`
//   display: flex;
//   padding: 32px 50px;
//   background: #ff0000;

//   width: 100%;

//   color: #fff;
//   margin: 30px auto;
//   div {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//     align-items: center;
//     @media screen and (max-width: 700px) {
//       flex-direction: column;
//     }
//   }
//   h3 {
//     font-size: 17px;
//     font-weight: 400;

//     span {
//       font-size: 28px;
//       font-weight: 700;
//       @media screen and (max-width: 700px) {
//         font-size: 23px;
//       }
//     }
//   }
//   button {
//     padding: 20px 50px;
//     background-color: #fff;
//     color: #000;
//     font-size: 18px;
//     font-weight: 700;
//     border-radius: 7px;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     transition: all 0.7s linear;
//     &:hover {
//       background-color: #0c55e9;
//       color: #fff;
//     }
//     @media screen and (max-width: 700px) {
//       margin-top: 13px;
//     }
//   }
// `;
const ProfileCard = styled.section`
  display: flex;
  padding: 32px 50px;
  background: #ff0000;

  width: 100%;

  color: #fff;
  margin: 30px auto 0;
  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }
  }
  h3 {
    font-size: 17px;
    font-weight: 400;

    span {
      font-size: 28px;
      font-weight: 700;
      @media screen and (max-width: 700px) {
        font-size: 23px;
      }
    }
  }
  button {
    padding: 20px 50px;
    background-color: #fff;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.7s linear;
    &:hover {
      background-color: #0c55e9;
      color: #fff;
    }
    @media screen and (max-width: 700px) {
      margin-top: 13px;
      padding: 20px;
    }
  }
`;
const HorizontalWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 20px;
`;

const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
  h6 {
    margin-top: 3px;
  }
`;

const Dots = styled.div`
  display: flex;
  margin-right: 20px;
`;
const Header = styled.h3`
  color: #0c55e9;
  font-size: 18px;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 35px;
    height: 5px;
    background: #0c55e9;
    pointer-events: none;

    z-index: 1;
  }
`;

export default OurTeam;
