import React from "react";
import styled from "styled-components";

const Profile = () => {
  const logo = process.env.PUBLIC_URL + "/FULLLOGO.png";
  return (
    <Wrapper>
      <Content className="wrapper">
        <Section1>
          <Logo src={logo} loading="lazy" />
          <Image src="https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg" />
          <Title1>Company Profile</Title1>
          <Title2>Seamless Tech Excellence</Title2>
          <Title3>Home of cutting-edge programming solutions</Title3>
        </Section1>
        <Section2>
          <Image2
            src="https://cdn.pixabay.com/photo/2016/04/04/14/12/monitor-1307227_1280.jpg"
            loading="lazy"
          />
          <Titles>
            <Title4>Welcome To</Title4>
            <Title5>Netgenix Tech Icons Limited</Title5>
          </Titles>
          <Title6>
            Netgenix Tech Icons is a cutting-edge technology solutions provider,
            dedicated to transforming ideas into innovative digital realities.
            Founded in June 2023, our company embodies a passion for excellence
            and a commitment to delivering exceptional tech solutions that
            empower businesses and individuals in the ever-evolving digital
            landscape.At Netgenix Tech Icons, we specialize in a wide range of
            tech services, each tailored to meet the unique needs of our
            clients.
          </Title6>
        </Section2>
      </Content>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100vw;
  background-color: whitesmoke;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;
const Section1 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  width: fit-content;
  padding: 30px;
  background: linear-gradient(to bottom, #fff 50%, #0c55e9 50%);
  box-shadow: 0px 4px 6px #0c55e9;
`;
const Logo = styled.img`
  width: 125px;
  height: 125px;
  cursor: pointer;
  object-fit: cover;
  display: flex;
  align-self: center;
  margin-bottom: 20px;
  @media screen and (max-width: 800px) {
    width: 105px;
    height: 105px;
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 700px;
  height: 500px;
  cursor: pointer;
  object-fit: contain;
  border: 8px solid #ff0000;
  border-radius: 50px 0 50px 0;
  /* @media screen and (max-width: 800px) {
    width: 105px;
    height: 105px;
  } */
`;
const Title1 = styled.h1`
  color: #fff;
  font-size: 64px;
  z-index: 5;
  margin-top: 10px;
  @media screen and (max-width: 700px) {
    font-size: 40px;
  }
  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
`;
const Title2 = styled.h1`
  color: #fff;
  font-size: 32px;
  font-style: italic;
  z-index: 5;
`;
const Title3 = styled.h1`
  color: #fff;
  font-size: 22px;
  z-index: 5;
  margin: 40px 0 30px;
`;
const Section2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;

  background: linear-gradient(to right, #cfd5e1 30%, #fff 30%);
  box-shadow: 3px 4px 6px #fff;
  width: 100%;
  max-width: 760px;
  height: fit-content;
`;
const Image2 = styled.img`
  width: 100%;
  max-width: 500px;
  height: 350px;
  cursor: pointer;
  object-fit: cover;
  margin-bottom: 70px;
  border-radius: 0 0 50px 0;
  display: flex;
  align-self: flex-start;
  /* @media screen and (max-width: 800px) {
    width: 105px;
    height: 105px;
  } */
`;
const Titles = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const Title4 = styled.h1`
  color: #000;
  font-size: 22px;
`;
const Title5 = styled.h1`
  color: #000;
  font-size: 28px;
  font-weight: 800;
`;
const Title6 = styled.h1`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  margin: 20px 50px;
  padding: 0 60px;
  line-height: 26px;
`;
export default Profile;
